exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\r\n.qrcodeModal_leap_qrcode_modal_3__it{\r\n    position: absolute;\r\n    z-index: 99;\r\n    width: 100%;\r\n    height: 100%;\r\n    background: rgba(0, 0, 0, 0.6);\r\n    border: 1px #666 solid;\r\n    text-align: center;\r\n    top: 0;\r\n    left: 0;\r\n}\r\n.qrcodeModal_leap_qrcode_container_3sIZS{\r\n    border: 1px #000 solid;\r\n    background: #fff;\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    -webkit-transform: translate(-50%,-50%);\r\n        -ms-transform: translate(-50%,-50%);\r\n            transform: translate(-50%,-50%);\r\n    border-radius: 10px;\r\n    padding: 5px 5px 50px;\r\n}\r\n.qrcodeModal_leap_qrcode_close_1Zdax{\r\n    margin-bottom: 50px;\r\n    overflow: hidden;\r\n}\r\n.qrcodeModal_leap_qrcode_close_button_2bX1J{\r\n    float: right;\r\n    margin: 0 5px 0 0 ;\r\n    text-decoration: none;\r\n    color: #666;\r\n}\r\n.qrcodeModal_leap_qrcode_button_YgUvC{\r\n    float: right;\r\n    color: #666;\r\n    text-decoration: none;\r\n}\r\n.qrcodeModal_leap_qrcode_url_3KveJ{\r\n    color: #666;\r\n    padding: 30px 50px 0;\r\n}", ""]);

// exports
exports.locals = {
	"leap_qrcode_modal": "qrcodeModal_leap_qrcode_modal_3__it",
	"leapQrcodeModal": "qrcodeModal_leap_qrcode_modal_3__it",
	"leap_qrcode_container": "qrcodeModal_leap_qrcode_container_3sIZS",
	"leapQrcodeContainer": "qrcodeModal_leap_qrcode_container_3sIZS",
	"leap_qrcode_close": "qrcodeModal_leap_qrcode_close_1Zdax",
	"leapQrcodeClose": "qrcodeModal_leap_qrcode_close_1Zdax",
	"leap_qrcode_close_button": "qrcodeModal_leap_qrcode_close_button_2bX1J",
	"leapQrcodeCloseButton": "qrcodeModal_leap_qrcode_close_button_2bX1J",
	"leap_qrcode_button": "qrcodeModal_leap_qrcode_button_YgUvC",
	"leapQrcodeButton": "qrcodeModal_leap_qrcode_button_YgUvC",
	"leap_qrcode_url": "qrcodeModal_leap_qrcode_url_3KveJ",
	"leapQrcodeUrl": "qrcodeModal_leap_qrcode_url_3KveJ"
};