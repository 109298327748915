exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes mic-indicator_popIn_2A2Un {\r\n    from {-webkit-transform: scale(0.5);transform: scale(0.5)}\r\n    to {-webkit-transform: scale(1);transform: scale(1)}\r\n}\r\n\r\n@keyframes mic-indicator_popIn_2A2Un {\r\n    from {-webkit-transform: scale(0.5);transform: scale(0.5)}\r\n    to {-webkit-transform: scale(1);transform: scale(1)}\r\n}\r\n\r\n.mic-indicator_mic-img_Su3yZ {\r\n    margin: 10px;\r\n    -webkit-transform-origin: center;\r\n        -ms-transform-origin: center;\r\n            transform-origin: center;\r\n    -webkit-animation: mic-indicator_popIn_2A2Un 0.1s ease-in-out;\r\n            animation: mic-indicator_popIn_2A2Un 0.1s ease-in-out;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"mic-img": "mic-indicator_mic-img_Su3yZ",
	"micImg": "mic-indicator_mic-img_Su3yZ",
	"popIn": "mic-indicator_popIn_2A2Un"
};