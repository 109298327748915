exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".leapModal_modal-container_UjYpc{\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background: rgba(0, 0, 0, 0.6);\r\n    z-index: 999;\r\n}\r\n.leapModal_modal-inside_1xCPs{\r\n    background: #fff;\r\n    overflow: hidden;\r\n    left: 50%;\r\n    top: 50%;\r\n    position: absolute;\r\n    -webkit-transform: translate(-50%,-50%);\r\n        -ms-transform: translate(-50%,-50%);\r\n            transform: translate(-50%,-50%);\r\n    border-radius: 5px;\r\n}\r\n.leapModal_modal-header_DJkhM{\r\n    overflow: hidden;\r\n    height: 50px;\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n}\r\n.leapModal_modal-header_DJkhM p{\r\n    float: left;\r\n    line-height: 40px;\r\n    margin: 0;\r\n    margin-left: 20px;\r\n    font-size: 18px;\r\n}\r\n.leapModal_modal-header_DJkhM span{\r\n    float: right;\r\n    line-height: 35px;\r\n    font-size: 20px;\r\n    margin-right: 10px;\r\n    cursor: pointer;\r\n    color: #bbb;\r\n}\r\n.leapModal_modal-body_3315S{\r\n    overflow: hidden;\r\n    position: absolute;\r\n    top: 50px;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    padding: 20px;\r\n}\r\n.leapModal_modal-body_3315S p {\r\n    margin: 0;\r\n}", ""]);

// exports
exports.locals = {
	"modal-container": "leapModal_modal-container_UjYpc",
	"modalContainer": "leapModal_modal-container_UjYpc",
	"modal-inside": "leapModal_modal-inside_1xCPs",
	"modalInside": "leapModal_modal-inside_1xCPs",
	"modal-header": "leapModal_modal-header_DJkhM",
	"modalHeader": "leapModal_modal-header_DJkhM",
	"modal-body": "leapModal_modal-body_3315S",
	"modalBody": "leapModal_modal-body_3315S"
};