exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index_leap-button_MbKMJ{\r\n  border: none;\r\n  background: none;\r\n  cursor: pointer;\r\n  display: inline-block;\r\n  padding:8px 12px;\r\n  margin-left:10px;\r\n  border-radius: 3px;\r\n  outline: none;\r\n}\r\n.index_type-default_DFc4G{\r\n  border: 1px solid #dcdfe6;\r\n  background: #fff;\r\n  color: #606266;\r\n}\r\n.index_type-primary_1qjgq{\r\n  background: #409eff;\r\n  color: #fff;\r\n}", ""]);

// exports
exports.locals = {
	"leap-button": "index_leap-button_MbKMJ",
	"leapButton": "index_leap-button_MbKMJ",
	"type-default": "index_type-default_DFc4G",
	"typeDefault": "index_type-default_DFc4G",
	"type-primary": "index_type-primary_1qjgq",
	"typePrimary": "index_type-primary_1qjgq"
};