import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import {connect} from 'react-redux';

import {defineMessages, injectIntl, intlShape} from 'react-intl';


import analytics from '../lib/analytics';
import LibraryComponent from '../components/library/library.jsx';
import extensionIcon from '../components/action-menu/icon--sprite.svg';

const messages = defineMessages({
    extensionTitle: {
        defaultMessage: 'Choose an Extension',
        description: 'Heading for the extension library',
        id: 'gui.gui.defaultProjectTitle'
    },
    extensionUrl: {
        defaultMessage: 'Enter the URL of the extension',
        description: 'Prompt for unoffical extension url',
        id: 'gui.extensionLibrary.extensionUrl'
    }
});

class WorksLibrary extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
    }
    handleItemSelect (item) {
        //console.log('查看作品',item.scratchUrl);
        let url = item.scratchUrl + "?v="+new Date().getTime() ;
        this.props.setProjectTitle(this.getProjectTitleFromFilename(item.name));
        this.props.onSelectWork(item.workdId);
        fetch(url,{
            method:"GET",
            dataType:"blob"
        })
        .then(res=>res.blob())
        .then(blob=>{
            var reader = new FileReader();
            reader.onload =(e)=> {
                //alert(e.target.result);
                this.initProject(e.target.result);
            }; 
            reader.readAsArrayBuffer(blob);
        })
        .catch(err=>{
            console.log("fetch error"+err);
        });
    }

    getProjectTitleFromFilename (fileInputFilename) {
        if (!fileInputFilename) return '';
        // only parse title with valid scratch project extensions
        // (.sb, .sb2, and .sb3)
        const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
        if (!matches) return fileInputFilename;
        return matches[1].substring(0, 100); // truncate project title to max 100 chars
    }

    initProject (data) {
        this.props.vm.loadProject(data)
        .then(() => {
            analytics.event({
                category: 'project',
                action: 'Import Project File',
                nonInteraction: true
            });
        })
        .catch(error => {
            log.warn(error);
            this.props.onLoadingFinished(this.props.loadingState, false);
        });
    }

    render () {

        const extensionLibraryThumbnailData = this.props.workList.map(extension => ({
            rawURL: extension.iconURL || extensionIcon,
            ...extension
        }));
        return (
            <LibraryComponent
                data={extensionLibraryThumbnailData}
                filterable={false}
                id="worksLibrary"
                isWork={true}
                title={this.props.intl.formatMessage(messages.extensionTitle)}
                visible={this.props.visible}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}


const mapStateToProps = state => ({
    workList: state.scratchGui.projectState.workList,
    vm: state.scratchGui.vm
});

WorksLibrary.propTypes = {
    intl: intlShape.isRequired,
    onCategorySelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    visible: PropTypes.bool,
    workList:PropTypes.array,
    onUpdateProjectTitle: PropTypes.func,
    onSelectWork:PropTypes.func,
    setProjectTitle:PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired // eslint-disable-line react/no-unused-prop-types
};

export default injectIntl(connect(
    mapStateToProps
)(WorksLibrary));
