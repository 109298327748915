import React from 'react';
import styles from './index.css';
import classNames from 'classnames';
import {connect} from 'react-redux';
class LeapMessage extends React.Component {
  constructor(){
    super()
  }
  render() {
    const {message} = this.props
    return (
      <div className={classNames(styles.leapMessageBox,{
        [styles.leapMessageDefault]:message.type === 'default',
        [styles.leapMessageSuccess]:message.type === 'success',
        [styles.leapMessageWarning]:message.type === 'warning',
        [styles.leapMessageError]:message.type === 'error',
      })}>
        {message.message}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return{
    message: state.scratchGui.message,
  }
}
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps,mapDispatchToProps)(LeapMessage);