const UPDATE_MESSAGE = 'scratch-gui/message/UPDATE_MESSAGE'
const UPDATE_MESSAGE_STATUS = 'scratch-gui/message/UPDATE_MESSAGE_STATUS'
const UPDATE_MESSAGE_BOX_STATUS = 'scratch-gui/message/UPDATE_MESSAGE_BOX_STATUS'
let timer = null;
const initialState = {
  type: 'default',
  message: '',
  isShow: false,
  boxShow:false,
  boxMessage:'',
  boxTitle:'',
  sucBack:()=>{
    console.log('成功')
  },
  errBack:()=>{
    console.log('取消')
  }
}
const reducer = function (state, action) {
  if (typeof state === 'undefined') state = initialState
  switch (action.type) {
  case UPDATE_MESSAGE:
    return Object.assign({}, state, {
      type:action.message.type || 'default',
      message:action.message.message || '',
      isShow:true
    });
  case UPDATE_MESSAGE_STATUS:
    return Object.assign({}, state, {
      isShow:false
    });
  case UPDATE_MESSAGE_BOX_STATUS:
    return Object.assign({}, state, {
      boxShow:action.message.boxShow,
      boxMessage:action.message.boxMessage,
      boxTitle:action.message.boxTitle|| 'Notes',
      sucBack:action.sucBack,
      errBack:action.errBack
    });
  default:
    return state;
  }
}

const updateMessage = function (dispatch,message) {
  timer = setTimeout(()=>{
    dispatch(updateMessageStatus())
  },message.delay*1000||2000)
  return {
      type: UPDATE_MESSAGE,
      message: message
  }
}
const updateMessageStatus = function(){
  clearTimeout(timer)
  timer = null
  return {
    type: UPDATE_MESSAGE_STATUS
  }
}

const clickMessageBox = (message,sucBack,errBack)=>{
  return {
    type: UPDATE_MESSAGE_BOX_STATUS,
    message,
    sucBack,
    errBack
  }
}
export {
  reducer as default,
  initialState as messageInitialState,
  updateMessage,
  clickMessageBox
}
