const OPEN_MODAL = 'scratch-gui/modals/OPEN_MODAL';
const CLOSE_MODAL = 'scratch-gui/modals/CLOSE_MODAL';

const MODAL_BACKDROP_LIBRARY = 'backdropLibrary';
const MODAL_CAMERA_CAPTURE = 'cameraCapture';
const MODAL_COSTUME_LIBRARY = 'costumeLibrary';
const MODAL_EXTENSION_LIBRARY = 'extensionLibrary';
const MODAL_LOADING_PROJECT = 'loadingProject';
const MODAL_TELEMETRY = 'telemetryModal';
const MODAL_SOUND_LIBRARY = 'soundLibrary';
const MODAL_SPRITE_LIBRARY = 'spriteLibrary';
const MODAL_SOUND_RECORDER = 'soundRecorder';
const MODAL_CONNECTION = 'connectionModal';
const MODAL_TIPS_LIBRARY = 'tipsLibrary';

// work
const MODAL_WORKS_LIBRARY = 'worksLibrary';

// timetable
const MODAL_TIMETABLE = 'timeTable';

// LeapLoading
const MODAL_LEAPLOADING = 'LeapLoading';

// LEAPshare
const MODAL_LEAPSHARE = 'leapShare'
// air-view
const MODAL_LEAPAIR = 'leapAir'
const MODAL_LEAPAIR_MATERIAL = 'leapAirMaterail'
// board
const MODAL_LEAP_BOARD = 'leapBoard'

const initialState = {
    [MODAL_BACKDROP_LIBRARY]: false,
    [MODAL_CAMERA_CAPTURE]: false,
    [MODAL_COSTUME_LIBRARY]: false,
    [MODAL_EXTENSION_LIBRARY]: false,
    [MODAL_LOADING_PROJECT]: false,
    [MODAL_TELEMETRY]: false,
    [MODAL_SOUND_LIBRARY]: false,
    [MODAL_SPRITE_LIBRARY]: false,
    [MODAL_SOUND_RECORDER]: false,
    [MODAL_CONNECTION]: false,
    [MODAL_TIPS_LIBRARY]: false,
    [MODAL_WORKS_LIBRARY]: false,
    [MODAL_TIMETABLE]: false,
    [MODAL_LEAPLOADING]: false,
    [MODAL_LEAPSHARE]: false,
    [MODAL_LEAPAIR]: false,
    [MODAL_LEAPAIR_MATERIAL]: false,
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case OPEN_MODAL:
        return Object.assign({}, state, {
            [action.modal]: true
        });
    case CLOSE_MODAL:
        return Object.assign({}, state, {
            [action.modal]: false
        });
    default:
        return state;
    }
};
const openModal = function (modal) {
    return {
        type: OPEN_MODAL,
        modal: modal
    };
};
const closeModal = function (modal) {
    return {
        type: CLOSE_MODAL,
        modal: modal
    };
};
const openBackdropLibrary = function () {
    return openModal(MODAL_BACKDROP_LIBRARY);
};
const openCameraCapture = function () {
    return openModal(MODAL_CAMERA_CAPTURE);
};
const openCostumeLibrary = function () {
    return openModal(MODAL_COSTUME_LIBRARY);
};
const openExtensionLibrary = function () {
    return openModal(MODAL_EXTENSION_LIBRARY);
};
const openLoadingProject = function () {
    return openModal(MODAL_LOADING_PROJECT);
};
const openTelemetryModal = function () {
    return openModal(MODAL_TELEMETRY);
};
const openSoundLibrary = function () {
    return openModal(MODAL_SOUND_LIBRARY);
};
const openSpriteLibrary = function () {
    return openModal(MODAL_SPRITE_LIBRARY);
};
const openSoundRecorder = function () {
    return openModal(MODAL_SOUND_RECORDER);
};
const openConnectionModal = function () {
    return openModal(MODAL_CONNECTION);
};
const openTipsLibrary = function () {
    return openModal(MODAL_TIPS_LIBRARY);
};
const closeBackdropLibrary = function () {
    return closeModal(MODAL_BACKDROP_LIBRARY);
};
const closeCameraCapture = function () {
    return closeModal(MODAL_CAMERA_CAPTURE);
};
const closeCostumeLibrary = function () {
    return closeModal(MODAL_COSTUME_LIBRARY);
};
const closeExtensionLibrary = function () {
    return closeModal(MODAL_EXTENSION_LIBRARY);
};
const closeLoadingProject = function () {
    return closeModal(MODAL_LOADING_PROJECT);
};
const closeTelemetryModal = function () {
    return closeModal(MODAL_TELEMETRY);
};
const closeSpriteLibrary = function () {
    return closeModal(MODAL_SPRITE_LIBRARY);
};
const closeSoundLibrary = function () {
    return closeModal(MODAL_SOUND_LIBRARY);
};
const closeSoundRecorder = function () {
    return closeModal(MODAL_SOUND_RECORDER);
};
const closeTipsLibrary = function () {
    return closeModal(MODAL_TIPS_LIBRARY);
};
const closeConnectionModal = function () {
    return closeModal(MODAL_CONNECTION);
};
// workd
const openWorksLibrary = function () {
    return openModal(MODAL_WORKS_LIBRARY);
};
const closeWorksLibrary = function () {
    return closeModal(MODAL_WORKS_LIBRARY);
};

//timeTable
const openTimeTable = function () {
    return openModal(MODAL_TIMETABLE);
};
const closeTimeTable = function () {
    return closeModal(MODAL_TIMETABLE);
};
// leaploading
const openLeapLoading = function () {
    return openModal(MODAL_LEAPLOADING);
};
const closeLeapLoading = function () {
    return closeModal(MODAL_LEAPLOADING);
};
//  leapShare
const openLeapShare = function () { //LeapShareVisible
    return openModal(MODAL_LEAPSHARE);
}
const closeLeapShare = function () { //LeapShareVisible
    return closeModal(MODAL_LEAPSHARE);
}
// air-view
const openLeapAir = function () {
    return openModal(MODAL_LEAPAIR);
}
const closeLeapAir = function () {
    return closeModal(MODAL_LEAPAIR);
}
const openLeapAirMaterial = function () {
    return openModal(MODAL_LEAPAIR_MATERIAL);
}
const closeLeapAirMaterial = function () {
    return closeModal(MODAL_LEAPAIR_MATERIAL);
}
// board
const openLeapBoard = function () {
    return openModal(MODAL_LEAP_BOARD);
}
const closeLeapBoard = function () {
    return closeModal(MODAL_LEAP_BOARD);
}

export {
    reducer as default,
    initialState as modalsInitialState,
    openBackdropLibrary,
    openCameraCapture,
    openCostumeLibrary,
    openExtensionLibrary,
    openLoadingProject,
    openSoundLibrary,
    openSpriteLibrary,
    openSoundRecorder,
    openTelemetryModal,
    openTipsLibrary,
    openConnectionModal,
    closeBackdropLibrary,
    closeCameraCapture,
    closeCostumeLibrary,
    closeExtensionLibrary,
    closeLoadingProject,
    closeSpriteLibrary,
    closeSoundLibrary,
    closeSoundRecorder,
    closeTelemetryModal,
    closeTipsLibrary,
    closeConnectionModal,
    // workd
    openWorksLibrary,
    closeWorksLibrary,
    //timeTable
    openTimeTable,
    closeTimeTable,
    //LeapLoading
    openLeapLoading,
    closeLeapLoading,
    //leapShare
    openLeapShare,
    closeLeapShare,
    
    openLeapAir,
    closeLeapAir,
    openLeapAirMaterial,
    closeLeapAirMaterial,

    openLeapBoard,
    closeLeapBoard
};
