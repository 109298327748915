export function offset(elem) {
  let actualLeft = elem.offsetLeft;
  let actualTop = elem.offsetTop;
  let current = elem.offsetParent;
  let actulWidth = elem.offsetWidth;
  let actulHeight = elem.offsetHeight;
  while (current !== null) {
    actualLeft += current.offsetLeft;
    actualTop += current.offsetTop;
    current = current.offsetParent;
  }
  return {
    left: actualLeft,
    top: actualTop,
    width: actulWidth,
    height: actulHeight
  }
}
export function dealPptUrl(pptUrl){
  let timeStop = '?time=' + new Date().getTime();
  let FileUrl = pptUrl.replace('/wopifiles/','');
  // let ncode = encodeURIComponent('https://wopi.leaplearner.com/wopi/files/' + FileUrl + timeStop);
  let splitArr = pptUrl.split(".");
  let finaleStr = splitArr[splitArr.length-1];
  let url = "";
  let ncode = 'https://lms' + process.env.GO_IN + '/uploads/tm/' + pptUrl + timeStop;
  if( ['ppt','pptx'].includes( finaleStr.toLowerCase() ) ){
    url = 'https://view.officeapps.live.com/op/embed.aspx?src=' + ncode + timeStop
  }else if( ['doc','docx'].includes( finaleStr.toLowerCase() ) ){
    url = 'https://view.officeapps.live.com/op/embed.aspx?src=' + ncode + timeStop
  } else {
    url = 'https://drive.google.com/viewerng/viewer?url=' + ncode + '&embedded=true'
  };
  return url
}

export function objectToUrl(object) {
  let arr = []
  for(let key in object) {
    arr.push(`${key}=${object[key]}`)
  }
  return arr.join('&')
}
