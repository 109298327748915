const leap={
    "getServerURL":function(){
        let url = process.env.serviceURL;
        // if (window.location.href.includes('127.0.0.1')) {
        //     url = "http://center.leaplearner.com/onlineplatform/"
        // }
        console.log("url:"+url);
        return url;
    },
    "getHomeURL":function(){
        let url = process.env.homeURL;
        console.log("url:"+url);
        return url;
    },
    "getCodeURL":function(){
        let url = process.env.codeURL;
        console.log("url:"+url);
        return url;
    }
};
export {
    leap as default
};