exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".box_box_2jjDp {\r\n}\r\n", ""]);

// exports
exports.locals = {
	"box": "box_box_2jjDp"
};