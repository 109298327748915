import analytics from '../lib/analytics';


const GET_USER_INFO = 'scratch-gui/user/GET_USER_INFO';
const UPDATE_LOADING_STATUS = 'scratch-gui/user/UPDATE_LOADING_STATUS';
const UPDATE_CURRENT_WORKID = 'scratch-gui/user/UPDATE_CURRENT_WORKID';
const UPDATE_FROM_STATUS ='scratch-gui/user/UPDATE_FROM_STATUS';
const UPDATE_BAG_SHOW = 'scratch-gui/user/UPDATE_BAG_SHOW';
const initialState = {
    userName:null,
    loading:true,
    currentProjectId:0,
    data:null,
    isLeapLogin:false,
    fromNewIde:false,
    bagShow:false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case GET_USER_INFO:
        return Object.assign({}, state, {
            userName: action.userName
        });
    case UPDATE_LOADING_STATUS:
        return Object.assign({}, state, {
            loading: false,
            data:action.data,
            isLeapLogin:action.data!=null?true:false
        });
    case UPDATE_CURRENT_WORKID:
        return Object.assign({}, state, {
            currentProjectId:action.workId
        });
    case UPDATE_FROM_STATUS:
        return Object.assign({}, state, {
            fromNewIde:action.fromStatus
        });
    case UPDATE_BAG_SHOW:
        return Object.assign({}, state, {
            bagShow:action.bagShow
        });
    default:
        return state;
    }
};

const getUserNameHandler = function (name) {
    return {
        type: GET_USER_INFO,
        userName:name
    };
};
const updateLoadingStatus = function (data) {
    console.log('data',data)
    return {
        type: UPDATE_LOADING_STATUS,
        data:data
    };
};
const updateWorkId = function (workId) {
    console.log("updateWorkId",workId);
    return {
        type: UPDATE_CURRENT_WORKID,
        workId:workId
    };
};
const updateFromStatus = function (fromStatus) {
    console.log('来自新的客户端',fromStatus)
    return {
        type: UPDATE_FROM_STATUS,
        fromStatus:fromStatus
    };
};

const updateBagShow = function(bagShow){
    return {
        type: UPDATE_BAG_SHOW,
        bagShow:bagShow
    };
}
export {
    reducer as default,
    initialState as userInitialState,
    getUserNameHandler,
    updateLoadingStatus,
    updateWorkId,
    updateFromStatus,
    updateBagShow
};
