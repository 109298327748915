import classNames from 'classnames';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import bowser from 'bowser';
import React from 'react';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import CommunityButton from './community-button.jsx';
import ShareButton from './share-button.jsx';
import {ComingSoonTooltip} from '../coming-soon/coming-soon.jsx';
import Divider from '../divider/divider.jsx';
import LanguageSelector from '../../containers/language-selector.jsx';
import SaveStatus from './save-status.jsx';
import SBFileUploader from '../../containers/sb-file-uploader.jsx';
import ProjectWatcher from '../../containers/project-watcher.jsx';
import MenuBarMenu from './menu-bar-menu.jsx';
import {MenuItem, MenuSection} from '../menu/menu.jsx';
import ProjectTitleInput from './project-title-input.jsx';
import AuthorInfo from './author-info.jsx';
import AccountNav from '../../containers/account-nav.jsx';
import LoginDropdown from './login-dropdown.jsx';
import SB3Downloader from '../../containers/sb3-downloader.jsx';
import SB4Downloader from '../../containers/sb4-downloader.jsx';
import DeletionRestorer from '../../containers/deletion-restorer.jsx';
import TurboMode from '../../containers/turbo-mode.jsx';

import {openTipsLibrary,openTimeTable,openLeapShare,openLeapBoard,openLeapAir,openLeapLoading,closeLeapLoading} from '../../reducers/modals';
import {setPlayer} from '../../reducers/mode';
import leap from '../../lib/leap';
import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy
} from '../../reducers/project-state';
import {
    updateFromStatus,
    updateWorkId,
    updateLoadingStatus,
    updateBagShow
} from '../../reducers/user';
import {
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen
} from '../../reducers/menus';

import styles from './menu-bar.css';

import helpIcon from '../../lib/assets/icon--tutorials.svg';
import mystuffIcon from './icon--mystuff.png';
import feedbackIcon from './icon--feedback.svg';
import profileIcon from './icon--profile.png';
import remixIcon from './icon--remix.svg';
import dropdownCaret from './dropdown-caret.svg';
import languageIcon from '../language-selector/language-icon.svg';

import scratchLogo from './scratch-logo.svg';

import sharedMessages from '../../lib/shared-messages';

import Cookies from 'js-cookie';
import analytics from '../../lib/analytics';
import { objectToUrl } from '../../leaplearner/utils'
import {
    setMaterialId,
    setTaskList,
    setTaskTitle,
    setTaskId,
    setSectionId
} from '../../reducers/material'
import {updateMessage,clickMessageBox} from '../../reducers/message'
import {dealPptUrl} from '../../leaplearner/utils'
const ariaMessages = defineMessages({
    language: {
        id: 'gui.menuBar.LanguageSelector',
        defaultMessage: 'language selector',
        description: 'accessibility text for the language selection menu'
    },
    tutorials: {
        id: 'gui.menuBar.tutorialsLibrary',
        defaultMessage: 'Tutorials',
        description: 'accessibility text for the tutorials button'
    }
});

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};


MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({id, isRtl, children, className}) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

class MenuBar extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            LEAP_STATUS : ''
        }
        bindAll(this, [
            'handleClickNew',
            'handleClickRemix',
            'handleClickSave',
            'handleClickSaveAsCopy',
            'handleClickSeeCommunity',
            'handleClickShare',
            'handleCloseFileMenuAndThen',
            'handleKeyPress',
            'handleLanguageMouseUp',
            'handleRestoreOption',
            'restoreOptionMessage',
            'handleOpenTimeTable',
            'shareProject',
            'handleBoard',
            'loginWithNewIde'
        ]);
    }
    componentDidMount () {
        document.addEventListener('keydown', this.handleKeyPress);
        window.onbeforeunload = null
        try{
            let href = window.location.href.split('?');
            let ahref = []
            let params = {}
            if(href.length>1) ahref = href[1].split('&')
            if(ahref.length) ahref.forEach(item=>{
                let obj = item.split('=')
                params[obj[0]] = obj[1]
            })
            if (params.type === '3' || params.type === '4') {
                // 新版本scratch过来隐藏 老版本一些按钮 this.props.fromNewIde = true  为新版本
                this.props.onUpdateFromStatus(true)
                if (params.materialId!=undefined) this.props.onSetMaterialId(params.materialId)
                // 判断什么模式
                if(params.createType === '2'){ // 上課模式
                    this.props.openAirModal()
                    this.props.onUpdateBagShow(true)
                    this.loginWithNewIde()
                } else if (params.createType === '3') { // 作業模式
                    if (params.userWorkId) {
                        // 中途退出
                        this.getScratchWork({
                            workId: params.userWorkId,
                            v:new Date().getTime()
                        },'HOMEWORK')
                    } else {
                        // 第一次创建
                        let workParams = {
                            classUserHomeworkDetailId: params.classUserHomeworkDetailId,
                            type: params.type,
                        }
                        if (params.defautWorkId != undefined && params.defautWorkId != 'undefined') {
                            workParams.defautWorkId = params.defautWorkId
                        }
                        this.createUserHomework(workParams)
                    }
                } else if (params.createType === '4') { // 编辑模式
                    this.loginWithNewIde()
                    this.getScratchWork({
                        workId: params.workId,
                        v:new Date().getTime()
                    },'EDITWORK')
                } else {
                    this.loginWithNewIde()
                }
                // 判断有无sectionId获取默认作品 、、 上课模式
                if (params.sectionId!=undefined) {
                    this.queryTaskBySectionCombobox({sectionId:params.sectionId})
                    this.props.onSetSectionId(params.sectionId)
                }
            } else {
                // 老版本 登录 +  作业模式
                this.loginSystem();
            }
        }catch(e){
            console.log('未登录，不做任何操作')
        }
        // 禁止弹出fail
        var WinAlert = window.alert;
        window.alert = function (e) {
            if (e == 'fail' ) {
                return false;
            }
            else {
                WinAlert(e);
            }
        }
    }
    // 新IDE的登录
    loginWithNewIde() {
        let info = {
            uname: Cookies.get('LEAP_SESSION_USER_INFO') || ''
        }
        let token = Cookies.get('leap_token');
        let url = leap.getServerURL()+"userpart/tokenLogin?"
        if( window.location.href.includes('127.0.0.1') ){
            token = 'LEAP_USER_3866b3b7-9b3f-40ac-ab61-153dd36d760b';//本地写死，否则无法获取cookie
        }
        url+="token="+token;
        fetch(url,{
            method:"GET"
        })
        .then(res=>res.json())
        .then(res=>{
            if(parseInt(res.status)==0){
                this.props.onGetUserInfo(res.data)
            } else {
                this.props.onGetUserInfo({data:info})
            }
        })
    }
    //作業模式 - 獲取作業代碼
    createUserHomework(params) {
        let rst = objectToUrl(params)
        if (rst) {
            this.props.onGetUserInfo();
            let url = leap.getServerURL() + 'hwp/createUserHomework?' + rst
            fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'}
            }).then(res=>res.json()).then(res=>{
                if (res.status == '0') {
                    this.selectHomework( res.data )
                    this.setState({
                        LEAP_STATUS: 'HOME_WORK'
                    })
                } else if (res.status == '60') {
                    this.props.onUpdateMessage({
                        type:'error',
                        message:this.props.translation.failCode60
                    })
                } else {
                    this.props.onUpdateMessage({
                        type:'error',
                        message:this.props.translation.failCode60
                    })
                }
            })
        }
    }
    // 编辑模式 - 等 - 获取scratch作品
    getScratchWork(params,type) {
        let rst = objectToUrl(params)
        if (rst) {
            this.props.onGetUserInfo();
            let url = leap.getServerURL() + 'scratch3/getScratchWork?' + rst
            fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'}
            }).then(res=>res.json()).then(res=>{
                if (res.code == '0') {
                    this.selectHomework( res.data )
                    if (type) {
                        this.setState({
                            LEAP_STATUS: 'EDIT_WORK'
                        })
                    }
                } else {
                    Cookies.set( 'homeworkStuMsg',JSON.stringify({}),{ path:'/',domain:process.env.GO_IN } )
                    this.props.onUpdateMessage({
                        type:'error',
                        message:this.props.translation.faile
                    })
                }
            }).catch(err => {
                Cookies.set( 'homeworkStuMsg',JSON.stringify({}),{ path:'/',domain:process.env.GO_IN } )
                this.props.onUpdateMessage({
                    type:'error',
                    message:this.props.translation.faile
                })
            })
        }
    }
    // 新版本获取默认代码
    getDefaultWork(body) {
        this.props.onOpenLeapLoading()
        this.props.onClickNew(false);
        let url = leap.getServerURL() + 'workapi/createWorkAfterLogin?' + body
        fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'}
        }).then(res=>res.json()).then(res=>{
            if (res.status === '0' && res.data && res.data.id) {
                this.props.onUpdateWorkId(res.data.id)
                this.props.onGetUserInfo({data:res.user});
                this.selectHomework( res.data )
            }
        }).catch(()=>{
            this.props.onGetUserInfo({data:{}});
            this.props.onCloseLeapLoading()
        })
    }
    // 小节ID获取作品
    queryTaskBySectionCombobox(data){
        let url = leap.getServerURL() + 'mc/queryTaskCombobox?' + objectToUrl(data)
        fetch(url,{
            method: 'POST',
            credentials: 'include',
            headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'}
        }).then(res=>res.json()).then(res=>{
            if(res.status === '0'){
                let section = res.section
                this.props.onSetTaskList({
                    pptUrl:dealPptUrl(section.fileUrl)||'',
                    taskList:res.data||[],
                    sectionName:section.sectionName
                })
                if (res.data&&res.data.length) {
                    this.props.onSetTaskTitle(res.data[0].task_title)
                    this.props.onSetTaskId(res.data[0].id)
                }
                this.getDefaultWork(`type=4&sectionId=${section.sectionId}&taskId=${res.taskIds[0]}`)
            } else {
                this.props.onGetUserInfo({data:{}})
            }
        }).catch(()=>{
            this.props.onGetUserInfo({data:{}})
        })
    }
    // 老-作业模式
    getWorkByHomework(){
        let homeworkStuMsg = Cookies.get('homeworkStuMsg')?JSON.parse( Cookies.get('homeworkStuMsg') ):{};
        if( homeworkStuMsg && homeworkStuMsg.workId ){
            this.getScratchWork({
                workId: homeworkStuMsg.workId,
                v:new Date().getTime()
            })
        }
    }
    // 选择作品
    selectHomework(work){
        let _this = this;
        if( !work.codeUrl || !work.id ) {
            if (work.id) this.props.onSelectWork( work.id);
            _this.props.onCloseLeapLoading()
            return;
        }
        let url = "https://cdn"+process.env.RESOURCE+"/" + work.codeUrl + "?v=" + new Date().getTime();
        let workName = this.props.language == 'zh-cn' ? (work.workName || '') : (work.workNameEn || '')
        this.props.setProjectTitle(workName);
        this.props.onSelectWork( work.id);
        fetch(url ,{
            method:"GET",
            dataType:"blob"
        })
        .then(res=>res.blob())
        .then(blob=>{
            var reader = new FileReader();
            reader.onload =(e)=> {
                let data =e.target.result;
                // delay. 15 seconds to prevent loading failure
                setTimeout(() => {
                    _this.props.vm.loadProject(data).then(() => {
                        console.log('The data is loaded - you can see the page')
                        analytics.event({
                            category: 'project',
                            action: 'Import Project File',
                            nonInteraction: true
                        });
                        _this.props.onCloseLeapLoading()
                        Cookies.set( 'homeworkStuMsg',JSON.stringify({}),{ path:'/',domain:process.env.GO_IN } )
                    })
                    .catch(error => {
                        console.log('error???', error)
                        Cookies.set( 'homeworkStuMsg',JSON.stringify({}),{ path:'/',domain:process.env.GO_IN } )
                        _this.props.onCloseLeapLoading()
                    });
                }, 15000)
            };
            reader.readAsArrayBuffer(blob);
        })
        .catch(err=>{
            console.log("fetch error"+err);
        });
    }
    // 登录
    loginSystem(cb){
        let token = Cookies.get('leap_token');
        let url = leap.getServerURL()+"user/loginWithToken?";
        if( window.location.href.includes('127.0.0.1') ){
            token = 'LEAP_USER_b66d66fb-cc93-416a-8433-d09176ce3323';//本地写死，否则无法获取cookie
        }
        if(token==null||token==""){
            this.props.onGetUserInfo();
            return;
        }
        if(token.indexOf("LEAP_GUEST_")>=0){
            this.props.onGetUserInfo();
            return;
        }
        url+="type=4&";
        url+="token="+token;
        return fetch(url,{
            method:"GET"
        })
        .then(res=>res.json())
        .then(res=>{
            console.log('登录',res)
            if(parseInt(res.status)==0){
                this.getWorkByHomework()
                if (cb) cb()
                this.props.onGetUserInfo(res.data);
            }else{
                this.props.onGetUserInfo(null);
            }
        })
        .catch(err=>{
            console.log("fetch error"+err);
            this.props.onGetUserInfo();
        });
    }

    logoutHandler(){
        var url=leap.getServerURL()+"user/logout";
        fetch(url,{
            method:"GET",
            credentials: 'include'
        })
        .then(res=>res.json())
        .then(res=>{
            if(parseInt(res.status)==0){
                window.location.reload();
                return;
            }
            this.props.onUpdateMessage({
                type:'error',
                message:this.props.translation.faile
            })
        })
        .catch(err=>{
            console.log("fetch error"+err);
            this.props.onUpdateMessage({
                type:'error',
                message:this.props.translation.faile
            })
        });
    }
    backToCodeHandler(){
        window.location.href="https://code"+process.env.GO_IN+"?courseTypeId=javascript";
    }

    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    handleClickNew () {
        if (this.props.projectChanged && !this.props.canCreateNew) {
            this.props.onClickMessageBox({
                boxTitle:this.props.translation.notes,
                boxMessage:this.props.translation.replaceWord,
                boxShow:true
            },()=>{
                this.props.onUpdateProjectTitle( this.props.translation.scratchWork );
                this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
            },()=>{
                this.props.onRequestCloseFile();
            })
        } else {
            this.props.onUpdateProjectTitle( this.props.translation.scratchWork );
            this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
            this.props.onRequestCloseFile();
        }
    }
    handleClickRemix () {
        this.props.onClickRemix();
        this.props.onRequestCloseFile();
    }
    handleClickSave () {
        this.props.onClickSave();
        this.props.onRequestCloseFile();
    }
    handleClickSaveAsCopy () {
        this.props.onClickSaveAsCopy();
        this.props.onRequestCloseFile();
    }
    handleClickSeeCommunity (waitForUpdate) {
        if (this.props.canSave) { // save before transitioning to project page
            this.props.autoUpdateProject();
            waitForUpdate(true); // queue the transition to project page
        } else {
            waitForUpdate(false); // immediately transition to project page
        }
    }
    handleClickShare (waitForUpdate) {
        if (!this.props.isShared) {
            if (this.props.canShare) { // save before transitioning to project page
                this.props.onShare();
            }
            if (this.props.canSave) { // save before transitioning to project page
                this.props.autoUpdateProject();
                waitForUpdate(true); // queue the transition to project page
            } else {
                waitForUpdate(false); // immediately transition to project page
            }
        }
    }
    handleRestoreOption (restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleCloseFileMenuAndThen (fn) {
        return () => {
            this.props.onRequestCloseFile();
            fn(this.state.LEAP_STATUS);
        };
    }
    handleKeyPress (event) {
        const modifier = bowser.mac ? event.metaKey : event.ctrlKey;
        if (modifier && event.key === 's') {
            this.props.onClickSave();
            event.preventDefault();
        }
    }
    handleLanguageMouseUp (e) {
        if (!this.props.languageMenuOpen) {
            this.props.onClickLanguage(e);
        }
    }
    handleOpenTimeTable(){
        this.props.onOpenTimeTable();
    }
    handleBoard(){
        this.props.onOpenLeapBoard()
    }
    shareProject(){
        if( this.props.currentProjectId == 0 ){
            this.props.onUpdateMessage({
                type:'warning',
                message:this.props.translation.saveBeforeShare
            })
            return;
        }
        this.props.onOpenLeapShare();
    }
    restoreOptionMessage (deletedItem) {
        switch (deletedItem) {
        case 'Sprite':
            return (<FormattedMessage
                defaultMessage="Restore Sprite"
                description="Menu bar item for restoring the last deleted sprite."
                id="gui.menuBar.restoreSprite"
            />);
        case 'Sound':
            return (<FormattedMessage
                defaultMessage="Restore Sound"
                description="Menu bar item for restoring the last deleted sound."
                id="gui.menuBar.restoreSound"
            />);
        case 'Costume':
            return (<FormattedMessage
                defaultMessage="Restore Costume"
                description="Menu bar item for restoring the last deleted costume."
                id="gui.menuBar.restoreCostume"
            />);
        default: {
            return (<FormattedMessage
                defaultMessage="Restore"
                description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                id="gui.menuBar.restore"
            />);
        }
        }
    }
    render () {
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage="Save now"
                description="Menu bar item for saving now"
                id="gui.menuBar.saveNow"
            />
        );
        const createCopyMessage = (
            <FormattedMessage
                defaultMessage="Save as a copy"
                description="Menu bar item for saving as a copy"
                id="gui.menuBar.saveAsCopy"
            />
        );
        const remixMessage = (
            <FormattedMessage
                defaultMessage="Remix"
                description="Menu bar item for remixing"
                id="gui.menuBar.remix"
            />
        );
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage="New"
                description="Menu bar item for creating a new project"
                id="gui.menuBar.new"
            />
        );
        const remixButton = (
            <Button
                className={classNames(
                    styles.menuBarButton,
                    styles.remixButton
                )}
                iconClassName={styles.remixButtonIcon}
                iconSrc={remixIcon}
                onClick={this.handleClickRemix}
            >
                {remixMessage}
            </Button>
        );
        return (
            <Box
                className={classNames(
                    this.props.className,
                    styles.menuBar
                )}
            >
                <div className={styles.mainMenu}>
                    <div className={styles.fileGroup}>
                        <div className={classNames(styles.menuBarItem)}>
                            {
                                this.props.fromNewIde ?
                                <img
                                    alt="Scratch"
                                    className={styles.scratchLogo}
                                    draggable={false}
                                    src={scratchLogo}
                                />:
                                <img
                                    alt="Scratch"
                                    className={classNames(styles.scratchLogo, {
                                        [styles.clickable]: typeof this.props.onClickLogo !== 'undefined'
                                    })}
                                    draggable={false}
                                    src={scratchLogo}
                                    onClick={this.props.onClickLogo}
                                />
                            }
                        </div>
                        <div className={classNames(styles.menuBarItem, styles.hoverable, styles.languageMenu)}>
                            <div>
                                <img
                                    className={styles.languageIcon}
                                    src={languageIcon}
                                />
                                <img
                                    className={styles.languageCaret}
                                    src={dropdownCaret}
                                />
                            </div>
                            <LanguageSelector label={this.props.intl.formatMessage(ariaMessages.language)} />
                        </div>
                        <div
                            className={classNames(styles.menuBarItem, styles.hoverable, {
                                [styles.active]: this.props.fileMenuOpen
                            })}
                            onMouseUp={this.props.onClickFile}
                        >
                            <FormattedMessage
                                defaultMessage="File"
                                description="Text for file dropdown menu"
                                id="gui.menuBar.file"
                            />
                            <MenuBarMenu
                                className={classNames(styles.menuBarMenu)}
                                open={this.props.fileMenuOpen}
                                place={this.props.isRtl ? 'left' : 'right'}
                                onRequestClose={this.props.onRequestCloseFile}
                            >
                                <MenuSection>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.handleClickNew}
                                    >
                                        {newProjectMessage}
                                    </MenuItem>
                                </MenuSection>
                                {(this.props.canSave || this.props.canCreateCopy || this.props.canRemix) && (
                                    <MenuSection>
                                        {this.props.canSave ? (
                                            <MenuItem onClick={this.handleClickSave}>
                                                {saveNowMessage}
                                            </MenuItem>
                                        ) : []}
                                        {this.props.canCreateCopy ? (
                                            <MenuItem onClick={this.handleClickSaveAsCopy}>
                                                {createCopyMessage}
                                            </MenuItem>
                                        ) : []}
                                        {this.props.canRemix ? (
                                            <MenuItem onClick={this.handleClickRemix}>
                                                {remixMessage}
                                            </MenuItem>
                                        ) : []}
                                    </MenuSection>
                                )}
                                {
                                this.props.isLeapLogin?
                                <MenuSection>
                                    <SBFileUploader isLoadComputer={true} onUpdateProjectTitle={this.props.onUpdateProjectTitle}>
                                        {(className, renderFileInput, loadProject) => (
                                            <MenuItem
                                                className={className}
                                                onClick={loadProject}
                                            >
                                            <FormattedMessage
                                                defaultMessage="Load from your computer"
                                                description='Menu bar item for uploading a project from your computer'
                                                id="gui.menuBar.uploadFromComputer"
                                            />
                                            {renderFileInput()}
                                            </MenuItem>
                                        )}
                                    </SBFileUploader>
                                </MenuSection>:
                                null
                                }
                                <MenuSection>
                                    {/* 电脑中上传/查看作品页面 */}
                                    <SBFileUploader onUpdateProjectTitle={this.props.onUpdateProjectTitle}>
                                        {(className, renderFileInput, loadProject) => (
                                            <MenuItem
                                                className={className}
                                                onClick={loadProject}
                                            >
                                            {
                                            this.props.isLeapLogin?
                                            <FormattedMessage
                                                defaultMessage="Save to your computer"
                                                description="Menu bar item for downloading a project to your computer"
                                                id="gui.menuBar.seeProjectPage"
                                            />:
                                            <FormattedMessage
                                                defaultMessage="Load from your computer"
                                                description='Menu bar item for uploading a project from your computer'
                                                id="gui.menuBar.uploadFromComputer"
                                            />}
                                                {renderFileInput()}
                                            </MenuItem>
                                        )}
                                    </SBFileUploader>
                                    {/* 立即保存 */}
                                </MenuSection>
                                {
                                    this.props.isLeapLogin?
                                    <MenuSection>
                                        <SB4Downloader>{(className, downloadProject) => (
                                            <MenuItem
                                                className={className}
                                                onClick={this.handleCloseFileMenuAndThen(downloadProject)}
                                            >
                                                { this.props.translation.saveLocal }
                                            </MenuItem>
                                        )}</SB4Downloader>
                                    </MenuSection>:null
                                }
                            </MenuBarMenu>
                        </div>
                        <div className={classNames(styles.menuBarItem, styles.hoverable)} onClick={this.handleBoard}>
                            <span>{this.props.translation.whiteboard }</span>
                        </div>
                        {/* 编辑 ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ */}
                        <div
                            className={classNames(styles.menuBarItem, styles.hoverable, {
                                [styles.active]: this.props.editMenuOpen
                            })}
                            onMouseUp={this.props.onClickEdit}
                        >
                            <div className={classNames(styles.editMenu)}>
                                <FormattedMessage
                                    defaultMessage="Edit"
                                    description="Text for edit dropdown menu"
                                    id="gui.menuBar.edit"
                                />
                            </div>
                            <MenuBarMenu
                                className={classNames(styles.menuBarMenu)}
                                open={this.props.editMenuOpen}
                                place={this.props.isRtl ? 'left' : 'right'}
                                onRequestClose={this.props.onRequestCloseEdit}
                            >
                                <DeletionRestorer>{(handleRestore, {restorable, deletedItem}) => (
                                    <MenuItem
                                        className={classNames({[styles.disabled]: !restorable})}
                                        onClick={this.handleRestoreOption(handleRestore)}
                                    >
                                        {this.restoreOptionMessage(deletedItem)}
                                    </MenuItem>
                                )}</DeletionRestorer>
                                <MenuSection>
                                    <TurboMode>{(toggleTurboMode, {turboMode}) => (
                                        <MenuItem onClick={toggleTurboMode}>
                                            {turboMode ? (
                                                <FormattedMessage
                                                    defaultMessage="Turn off Turbo Mode"
                                                    description="Menu bar item for turning off turbo mode"
                                                    id="gui.menuBar.turboModeOff"
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    defaultMessage="Turn on Turbo Mode"
                                                    description="Menu bar item for turning on turbo mode"
                                                    id="gui.menuBar.turboModeOn"
                                                />
                                            )}
                                        </MenuItem>
                                    )}</TurboMode>
                                </MenuSection>
                            </MenuBarMenu>
                        </div>
                    </div>
                    <Divider className={classNames(styles.divider)} />
                    {this.props.canEditTitle ? (
                        <div className={classNames(styles.menuBarItem, styles.growable)}>
                            <MenuBarItemTooltip
                                enable
                                id="title-field"
                            >
                                <ProjectTitleInput
                                    className={classNames(styles.titleFieldGrowable)}
                                    onUpdateProjectTitle={this.props.onUpdateProjectTitle}
                                />
                            </MenuBarItemTooltip>
                        </div>
                    ) : ((this.props.authorUsername && this.props.authorUsername !== this.props.username) ? (
                        <AuthorInfo
                            className={styles.authorInfo}
                            imageUrl={this.props.authorThumbnailUrl}
                            projectTitle={this.props.projectTitle}
                            userId={this.props.authorId}
                            username={this.props.authorUsername}
                        />
                    ) : null)}
                    {
                        this.props.isLeapLogin ?
                        <React.Fragment>
                            <Divider className={classNames(styles.divider)} />
                            {
                                this.props.fromNewIde ?
                                null :
                                <div className={classNames(styles.menuBarItem, styles.hoverable)} style={ {"padding":"0 10px"} } onClick={this.handleOpenTimeTable}>
                                    <span>{this.props.translation.material }</span>
                                </div>
                            }
                            <SB3Downloader>{(className, downloadProject) => (
                                <div className={classNames(styles.menuBarItem, styles.hoverable)} style={ {"padding":"0 10px"} } onClick={this.handleCloseFileMenuAndThen(downloadProject)}>
                                    <span>{this.props.translation.save}</span>
                                </div>
                            )}</SB3Downloader>


                            <div className={classNames(styles.menuBarItem, styles.hoverable)} style={ {"padding":"0 10px"} } onClick={this.shareProject}>
                                <span>{this.props.translation.share}</span>
                            </div>


                        </React.Fragment> : null
                    }

                    {
                        !this.props.isLeapLogin && this.state.LEAP_STATUS !== '' ?
                        <SB3Downloader>{(className, downloadProject) => (
                            <div className={classNames(styles.menuBarItem, styles.hoverable)} style={ {"padding":"0 10px"} } onClick={this.handleCloseFileMenuAndThen(downloadProject)}>
                                <span>{this.props.translation.save}</span>
                            </div>
                        )}</SB3Downloader>
                        :null
                    }



                    <div className={classNames(styles.menuBarItem)}>
                        {this.props.canShare ? (
                            (this.props.isShowingProject || this.props.isUpdating) && (
                                <ProjectWatcher onDoneUpdating={this.props.onSeeCommunity}>
                                    {
                                        waitForUpdate => (
                                            <ShareButton
                                                className={styles.menuBarButton}
                                                isShared={this.props.isShared}
                                                /* eslint-disable react/jsx-no-bind */
                                                onClick={() => {
                                                    this.handleClickShare(waitForUpdate);
                                                }}
                                                /* eslint-enable react/jsx-no-bind */
                                            />
                                        )
                                    }
                                </ProjectWatcher>
                            )
                        ) : null}
                        {this.props.canRemix ? remixButton : []}
                    </div>

                </div>

                {/* show the proper UI in the account menu, given whether the user is
                logged in, and whether a session is available to log in with */}
                <div className={styles.accountInfoGroup}>
                    <div className={styles.menuBarItem}>
                        {this.props.canSave && (
                            <SaveStatus />
                        )}
                    </div>
                    {
                        this.props.fromNewIde ?
                        <span style={{'paddingRight':'20px'}}>
                            { this.props.username}
                        </span> : this.props.username ? (
                            // ************ user is logged in ************
                            <React.Fragment>
                                <span>
                                    { this.props.username}
                                </span>

                                    <div onClick={this.logoutHandler}
                                        className={classNames(
                                            styles.menuBarItem,
                                            styles.hoverable,
                                            styles.mystuffButton
                                        )}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Sign out"
                                            description="Logout Button"
                                            id="gui.accountMenu.signOut"
                                        />
                                    </div>
                                    <div onClick={this.backToCodeHandler}
                                        className={classNames(
                                            styles.menuBarItem,
                                            styles.hoverable,
                                            styles.mystuffButton
                                        )}
                                    >
                                        { this.props.translation.platForm }
                                    </div>
                            </React.Fragment>
                        ) : (
                            // ********* user not logged in, but a session exists
                            // ********* so they can choose to log in
                            <React.Fragment>
                                <div
                                    className={classNames(
                                        styles.menuBarItem,
                                        styles.hoverable
                                    )}
                                    key="login"
                                    onMouseUp={this.props.onClickLogin}
                                >
                                    <FormattedMessage
                                        defaultMessage="Sign in"
                                        description="Link for signing in to your Scratch account"
                                        id="gui.menuBar.signIn"
                                    />

                                </div>
                            </React.Fragment>
                        )
                    }
                </div>
            </Box>
        );
    }
}

MenuBar.propTypes = {
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    editMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isUpdating: PropTypes.bool,
    languageMenuOpen: PropTypes.bool,
    loginMenuOpen: PropTypes.bool,
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLanguage: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
    projectChanged: PropTypes.bool,
    projectTitle: PropTypes.string,
    renderLogin: PropTypes.func,
    sessionExists: PropTypes.bool,
    showComingSoon: PropTypes.bool,
    username: PropTypes.string,
    onGetUserInfo: PropTypes.func,
    isLeapLogin:PropTypes.bool,
    onLogout:PropTypes.func,
    onOpenTimeTable:PropTypes.func,
    onOpenLeapShare:PropTypes.func,
    onOpenLeapBoard:PropTypes.func,
    onSelectWork:PropTypes.func,
    setProjectTitle:PropTypes.func,
};

MenuBar.defaultProps = {
    onShare: () => {}
};

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    // const user = state.session && state.session.session && state.session.session.user;
    const user = state.scratchGui && state.scratchGui.user && state.scratchGui.user.data;
    return {
        accountMenuOpen: accountMenuOpen(state),
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        isRtl: state.locales.isRtl,
        language: state.locales.locale,
        translation: state.locales.translation,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        languageMenuOpen: languageMenuOpen(state),
        loginMenuOpen: loginMenuOpen(state),
        projectChanged: state.scratchGui.projectChanged,
        projectTitle: state.scratchGui.projectTitle,
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        username: user ? user.data ? user.data.uname : user.uname : null,
        isLeapLogin:state.scratchGui.user.isLeapLogin,
        // vm: state.scratchGui.vm,
        currentProjectId: state.scratchGui.user.currentProjectId,
        fromNewIde: state.scratchGui.user.fromNewIde
    };
};

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    // onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onClickNew: needSave => {
        dispatch(requestNewProject(needSave))
        dispatch(updateWorkId(0));
    },
    onUpdateWorkId: id => dispatch(updateWorkId(id)),
    onUpdateFromStatus: status => dispatch(updateFromStatus(status)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    onClickLogin: () => {
        window.location.href=leap.getHomeURL();
    },
    onGetUserInfo:(data)=>{
        dispatch(updateLoadingStatus(data))
    },
    onOpenTimeTable:() => dispatch(openTimeTable()),
    onOpenLeapShare:() => dispatch(openLeapShare()),
    onOpenLeapBoard:() => dispatch(openLeapBoard()),
    openAirModal:() => dispatch(openLeapAir()),
    onSetMaterialId:id => dispatch(setMaterialId(id)),
    onSetTaskList:data => dispatch(setTaskList(data)),
    onSetTaskTitle:data => dispatch(setTaskTitle(data)),
    onSetTaskId:id => dispatch(setTaskId(id)),
    onUpdateBagShow:status => dispatch(updateBagShow(status)),
    onUpdateMessage:data => dispatch(updateMessage(dispatch,data)),
    onClickMessageBox:(data,sucB,errB) => dispatch(clickMessageBox(data,sucB,errB)),
    onSetSectionId: id => dispatch(setSectionId(id)),
    onOpenLeapLoading: () => dispatch(openLeapLoading()),
    onCloseLeapLoading: () => dispatch(closeLeapLoading()),
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuBar));
