exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index_leap-modal_ekmAr{\r\n  position: absolute;\r\n  left: 0;\r\n  right: 0;\r\n  top: 0;\r\n  bottom: 0;\r\n  background: rgba(0, 0, 0, .6);\r\n  z-index: 999;\r\n}\r\n.index_leap-message-box_1iAAc{\r\n  position: absolute;\r\n  left: 50%;\r\n  top: 50%;\r\n  -webkit-transform: translate(-50%, -50%);\r\n      -ms-transform: translate(-50%, -50%);\r\n          transform: translate(-50%, -50%);\r\n  background: #fff;\r\n  width: 420px;\r\n  border-radius: 5px;\r\n}\r\n.index_leap-message-head_2d5kY{\r\n  padding: 15px;\r\n  font-size: 18px;\r\n  position: relative;\r\n}\r\n.index_close-message_2LT4o{\r\n  position: absolute;\r\n  right: 15px;\r\n  top: 15px;\r\n  font-size: 16px;\r\n  color: #bbb;\r\n  cursor: pointer;\r\n}\r\n.index_leap-message-body_9ENGL{\r\n  padding: 10px 15px;\r\n}\r\n.index_leap-message-foot_3z2Ca{\r\n  padding: 5px 15px 15px;\r\n  overflow: hidden;\r\n}\r\n.index_leap-button_1_KhL{\r\n  float: right;\r\n}", ""]);

// exports
exports.locals = {
	"leap-modal": "index_leap-modal_ekmAr",
	"leapModal": "index_leap-modal_ekmAr",
	"leap-message-box": "index_leap-message-box_1iAAc",
	"leapMessageBox": "index_leap-message-box_1iAAc",
	"leap-message-head": "index_leap-message-head_2d5kY",
	"leapMessageHead": "index_leap-message-head_2d5kY",
	"close-message": "index_close-message_2LT4o",
	"closeMessage": "index_close-message_2LT4o",
	"leap-message-body": "index_leap-message-body_9ENGL",
	"leapMessageBody": "index_leap-message-body_9ENGL",
	"leap-message-foot": "index_leap-message-foot_3z2Ca",
	"leapMessageFoot": "index_leap-message-foot_3z2Ca",
	"leap-button": "index_leap-button_1_KhL",
	"leapButton": "index_leap-button_1_KhL"
};