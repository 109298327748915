exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".monitor-list_monitor-list_20k-y {\r\n    /* Width/height are set by the component, margin: auto centers in fullscreen */\r\n    margin: auto;\r\n    pointer-events: none;\r\n    overflow: hidden;\r\n}\r\n\r\n.monitor-list_monitor-list-scaler_143tA {\r\n    /* Scaling for monitors should happen from the top left */\r\n    -webkit-transform-origin: left top;\r\n        -ms-transform-origin: left top;\r\n            transform-origin: left top;\r\n}\r\n\r\n::-ms-clear { display: none; }\r\n", ""]);

// exports
exports.locals = {
	"monitor-list": "monitor-list_monitor-list_20k-y",
	"monitorList": "monitor-list_monitor-list_20k-y",
	"monitor-list-scaler": "monitor-list_monitor-list-scaler_143tA",
	"monitorListScaler": "monitor-list_monitor-list-scaler_143tA"
};