import { offset } from '../utils'
import eraser from './images/eraser.png'
import pencil from './images/pencil.png'
export default class Cavs {
  constructor(options) {
    this.bool = false
    this.options = options || {}
    this.cavs = null
    this.ctx = null
  }
  static getInstance(options) {
    if (this.instance == null) {
      this.instance = new Cavs(options)
    }
    return this.instance
  }
  init(id, width, height) {
    this.cavs = document.getElementById(id)
    this.ctx = this.cavs.getContext('2d')
    this.cavs.width = width
    this.cavs.height = height
  }
  cavsDestory() {
    this.instance = new Cavs()
  }
  drawLine() {
    this.cavs.style.cursor = `url(${pencil}),auto`
    let xy = offset(this.cavs)
    let cx = xy.left
    let cy = xy.top
    this.cavs.onmousedown = e => {
      e = e || window.event
      e.stopPropagation()
      this.bool = true
      let mx = e.pageX - cx
      let my = e.pageY - cy + 20
      this.ctx.strokeStyle = this.options.strokeStyle || '#FE2707'
      this.ctx.lineWidth = this.options.lineWidth || 2
      this.ctx.beginPath()
      this.ctx.moveTo(mx, my)
      // 画笔移动
      this.cavs.onmousemove = _e => {
        if (this.bool) {
          this.ctx.lineTo(_e.pageX - cx, _e.pageY - cy + 20)
          this.ctx.stroke()
        }
      };
      // 画笔抬起
      this.cavs.onmouseup = _e => {
        this.ctx.closePath()
        this.bool = false
      };
      // 画笔离开
      this.cavs.onmouseleave = _e => {
        this.ctx.closePath()
        this.bool = false
      };
    }
  }
  drawText(name, bias) {
    this.cavs.style.cursor = 'text'
    let biasObj = { // 偏差值
      left: bias.left || 0,
      top: bias.top || 0
    }
    this.cavs.onmousedown = e => {
      let xy = offset(this.cavs)
      let text = document.createElement('input')
      let width = xy.width + xy.left - e.clientX
      let left = e.clientX - xy.left
      let top = e.clientY - xy.top - biasObj.top
      text.style.background = 'transparent'
      text.style.outline = 'none'
      text.style.border = 'none'
      text.style.position = 'absolute'
      text.style.left = left + 'px'
      text.style.top = top + 'px'
      text.style.width = width + 'px'
      text.style.height = (this.options.fontSize || 18) + 'px'
      text.style.fontSize = (this.options.fontSize || 18) + 'px'
      text.style.fontFamily = this.options.fontFamily || 'sans-serif'
      text.style.color = this.options.fillStyle || '#FE2707'
      // text.setAttribute('contenteditable', true)
      document.querySelector(name).appendChild(text)
      const drawFun = content => {
        this.ctx.font = `${this.options.fontSize || 18}px ${this.options.fontFamily || 'sans-serif'}`
        this.ctx.fillStyle = this.options.fillStyle || '#FE2707'
        this.ctx.textAlign = 'stgart'
        this.ctx.textBaseline = 'top'
        this.ctx.fillText(content, left, top + biasObj.top / 4)
        if (text) {
          text.onblur = null
          text.onkeydown = null
          document.querySelector(name).removeChild(text)
        }
      }
      text.onblur = e => {
        drawFun(text.value)
      }
      text.onkeydown = e => {
        if (e.keyCode === 13) {
          drawFun(text.value)
        }
      }
      setTimeout(() => {
        text.focus()
      })
    }
  }
  drawEraser() {
    this.cavs.style.cursor = `url(${eraser}),auto`
    let xy = offset(this.cavs)
    let cx = xy.left
    let cy = xy.top
    this.cavs.onmousedown = e => {
      e = e || window.event
      e.stopPropagation()
      this.bool = true
      this.cavs.onmousemove = _e => {
        if (this.bool) {
          this.ctx.clearRect(_e.pageX - cx, _e.pageY - cy + 10, 20, 20)
        }
      }
      this.cavs.onmouseup = _e => {
        this.bool = false
      }
      this.cavs.onmouseleave = _e => {
        this.bool = false
      }
    }
  }
  drawClear() {
    this.ctx.clearRect(0, 0, this.cavs.width, this.cavs.height)
    this.allDie()
  }
  changeOptions(newOptions) {
    this.options = newOptions
  }
  allDie() {
    this.cavs.onmousedown = null
    this.cavs.onmousemove = null
    this.cavs.onmouseup = null
    this.cavs.onmouseleave = null
    this.cavs.style.cursor = 'default'
  }
}
