import React from 'react';
import styles from './leapLoading.css';

const LeapLoading = props => (
    <div
        className = { styles.loadingBox }
    >
        <div className={ styles.loadingInner }>
            <div className={styles.loadingBody}>
            </div>
        </div>
    </div>
)
export default LeapLoading;

// import {connect} from 'react-redux';
// import {closeLeapLoading} from '../../reducers/modals';
// class LeapLoading extends React.Component{
//     constructor(){
//         super();
//     }
//     componentDidMount(){
//         let timer = setTimeout(()=>{
//             clearTimeout( timer );
//             timer = null;
//             this.props.onCloseLeapLoading();
//         },4000 )
//     }
//     render(){
//         return (
//             <div className = { styles.loadingBox }>
//                 <div className={ styles.loadingInner }>
//                     <div className={styles.loadingBody}>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }
// const mapStateToProps = state => ({
//     LeapLoadingVisible:state.scratchGui.modals.LeapLoading
// })
// const mapDispatchToProps = dispatch => ({
//     onCloseLeapLoading:() => dispatch(closeLeapLoading())
// })
// export default connect(mapStateToProps,mapDispatchToProps)(LeapLoading);