exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".controls_controls-container_2xinB {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"controls-container": "controls_controls-container_2xinB",
	"controlsContainer": "controls_controls-container_2xinB"
};