import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import LibraryItem from '../../containers/library-item.jsx';
import Modal from '../../containers/modal.jsx';
import QrModal from '../qrcodeModal/qrcodeModal.jsx';
import Divider from '../divider/divider.jsx';
import Filter from '../filter/filter.jsx';
import TagButton from '../../containers/tag-button.jsx';
import analytics from '../../lib/analytics';

import styles from './library.css';
import {updateMessage,clickMessageBox} from '../../reducers/message'

import {
    onGetWorkList
} from '../../reducers/project-state';
import {connect} from 'react-redux';

import leap from '../../lib/leap';

const messages = defineMessages({
    filterPlaceholder: {
        id: 'gui.library.filterPlaceholder',
        defaultMessage: 'Search',
        description: 'Placeholder text for library search field'
    },
    allTag: {
        id: 'gui.library.allTag',
        defaultMessage: 'All',
        description: 'Label for library tag to revert to all items after filtering by tag.'
    }
});

const ALL_TAG = {tag: 'all', intlLabel: messages.allTag};
const tagListPrefix = [ALL_TAG];

class LibraryComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'handleFilterChange',
            'handleFilterClear',
            'handleMouseEnter',
            'handleMouseLeave',
            'handleSelect',
            'handleTagClick',
            'setFilteredDataRef',
            'handleShare',
            'handleDelete',
            'clearShareUrl',
        ]);
        this.state = {
            selectedItem: null,
            filterQuery: '',
            selectedTag: ALL_TAG.tag,
            shareUrl:""
        };
    }
    componentDidUpdate (prevProps, prevState) {
        if (prevState.filterQuery !== this.state.filterQuery ||
            prevState.selectedTag !== this.state.selectedTag) {
            this.scrollToTop();
        }
    }
    componentDidMount(){
        this.setState({ shareUrl:"" })
    }
    handleSelect (id) {
        this.handleClose();
        this.props.onItemSelected(this.getFilteredData()[id]);
    }
    handleClose () {
        this.props.onRequestClose();
        analytics.pageview(`/${this.props.id}/search?q=${this.state.filterQuery}`);
    }
    handleTagClick (tag) {
        this.setState({
            filterQuery: '',
            selectedTag: tag.toLowerCase()
        });
    }
    handleMouseEnter (id) {
        if (this.props.onItemMouseEnter) this.props.onItemMouseEnter(this.getFilteredData()[id]);
    }
    handleMouseLeave (id) {
        if (this.props.onItemMouseLeave) this.props.onItemMouseLeave(this.getFilteredData()[id]);
    }
    handleDelete(id){
        let objItem = this.getFilteredData()[id];
        this.props.onClickMessageBox({
            boxTitle:this.props.translation.notes,
            boxMessage:this.props.translation.isDelete,
            boxShow:true
        },()=>{
            let url=leap.getServerURL()+"workapi/delWork?workId="+objItem.workdId;
            fetch(url,{"method":"POST",credentials: 'include'})
            .then(res=>res.json())
            .then(res=>{
                console.log('res-del',res)
                if(parseInt(res.status)==0){
                    this.loadScratchList();
                    return;
                } else if (parseInt(res.status)==59) {
                    this.props.onUpdateMessage({
                        type:'error',
                        message:this.props.translation.faileCode59
                    })
                    return;
                }
                this.props.onUpdateMessage({
                    type:'error',
                    message:this.props.translation.faile
                }) 
            })
            .catch(err=>alert(err))
        },()=>{
            this.props.onClickMessageBox({
            boxShow:false
            })
            this.props.onUpdateMessage({
                type:'error',
                message:this.props.translation.cancel
            }) 
        })
    }
    loadScratchList(){
        let url=leap.getServerURL()+"scratch3/getScratchWorks";
        fetch(url,{"method":"GET",credentials: 'include'})
        .then(res=>res.json())
        .catch(err=>{
            alert(err);
        })
        .then(response=>{
            console.log("res-list",response);
            if(response.code==0){
                this.props.onGetWorkList(response.data);
            }
        });
    }
    handleShare(id){
        let objItem = this.getFilteredData()[id];
        
        this.setState({
            shareUrl:`https://scratch${process.env.GO_IN}/player.html?id=${objItem.workdId}`
        })

    }
    handleFilterChange (event) {
        this.setState({
            filterQuery: event.target.value,
            selectedTag: ALL_TAG.tag
        });
    }
    handleFilterClear () {
        this.setState({filterQuery: ''});
    }
    getFilteredData () {
        if (this.state.selectedTag === 'all') {
            if (!this.state.filterQuery) return this.props.data;
            return this.props.data.filter(dataItem => (
                (dataItem.tags || [])
                    // Second argument to map sets `this`
                    .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                    .concat(dataItem.name ?
                        (typeof dataItem.name === 'string' ?
                        // Use the name if it is a string, else use formatMessage to get the translated name
                            dataItem.name : this.props.intl.formatMessage(dataItem.name.props)
                        ).toLowerCase() :
                        null)
                    .join('\n') // unlikely to partially match newlines
                    .indexOf(this.state.filterQuery.toLowerCase()) !== -1
            ));
        }
        return this.props.data.filter(dataItem => (
            dataItem.tags &&
            dataItem.tags
                .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                .indexOf(this.state.selectedTag) !== -1
        ));
    }
    scrollToTop () {
        this.filteredDataRef.scrollTop = 0;
    }
    setFilteredDataRef (ref) {
        this.filteredDataRef = ref;
    }
    clearShareUrl(){
        this.setState({
            shareUrl:""
        })
    }
    render () {
        return (
            <Modal
                fullScreen
                contentLabel={this.props.title}
                id={this.props.id}
                onRequestClose={this.handleClose}
            >
                {(this.props.filterable || this.props.tags) && (
                    <div className={styles.filterBar}>
                        {this.props.filterable && (
                            <Filter
                                className={classNames(
                                    styles.filterBarItem,
                                    styles.filter
                                )}
                                filterQuery={this.state.filterQuery}
                                inputClassName={styles.filterInput}
                                placeholderText={this.props.intl.formatMessage(messages.filterPlaceholder)}
                                onChange={this.handleFilterChange}
                                onClear={this.handleFilterClear}
                            />
                        )}
                        {this.props.filterable && this.props.tags && (
                            <Divider className={classNames(styles.filterBarItem, styles.divider)} />
                        )}
                        {this.props.tags &&
                            <div className={styles.tagWrapper}>
                                {tagListPrefix.concat(this.props.tags).map((tagProps, id) => (
                                    <TagButton
                                        active={this.state.selectedTag === tagProps.tag.toLowerCase()}
                                        className={classNames(
                                            styles.filterBarItem,
                                            styles.tagButton,
                                            tagProps.className
                                        )}
                                        key={`tag-button-${id}`}
                                        onClick={this.handleTagClick}
                                        {...tagProps}
                                    />
                                ))}
                            </div>
                        }
                    </div>
                )}
                <div
                    className={classNames(styles.libraryScrollGrid, {
                        [styles.withFilterBar]: this.props.filterable || this.props.tags
                    })}
                    ref={this.setFilteredDataRef}
                >
                    {this.getFilteredData().map((dataItem, index) => (
                        <LibraryItem
                            bluetoothRequired={dataItem.bluetoothRequired}
                            collaborator={dataItem.collaborator}
                            description={dataItem.description}
                            disabled={dataItem.disabled}
                            extensionId={dataItem.extensionId}
                            featured={dataItem.featured}
                            hidden={dataItem.hidden}
                            iconMd5={dataItem.md5}
                            iconRawURL={dataItem.rawURL}
                            icons={dataItem.json && dataItem.json.costumes}
                            id={index}
                            insetIconURL={dataItem.insetIconURL}
                            internetConnectionRequired={dataItem.internetConnectionRequired}
                            key={`item_${index}`}
                            name={dataItem.name}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                            onSelect={this.handleSelect}
                            handleDelete = {this.handleDelete}
                            handleShare = {this.handleShare}
                            workId = { dataItem.workdId }
                        />
                    ))}
                </div>
                {
                    this.state.shareUrl != ""?
                    <QrModal
                        shareUrl={this.state.shareUrl}
                        clearShareUrl={this.clearShareUrl}
                    >
                    </QrModal>:null
                }
                
                
            </Modal>
        );
    }
}

LibraryComponent.propTypes = {
    data: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        // An item in the library
        PropTypes.shape({
            // @todo remove md5/rawURL prop from library, refactor to use storage
            md5: PropTypes.string,
            name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node
            ]),
            rawURL: PropTypes.string
        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),
    filterable: PropTypes.bool,
    id: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onItemMouseEnter: PropTypes.func,
    onItemMouseLeave: PropTypes.func,
    onItemSelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    tags: PropTypes.arrayOf(PropTypes.shape(TagButton.propTypes)),
    title: PropTypes.string.isRequired,
    isWork:PropTypes.bool,
};

LibraryComponent.defaultProps = {
    filterable: true,
    isWork:false
};
const mapStateToProps = state => {
    return {
        vm: state.scratchGui.vm,
        translation: state.locales.translation
    };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
    onGetWorkList: list=> {
        return new Promise( (resolve,reject)=>{
            dispatch(onGetWorkList(list))
            resolve(true)
        } )
    },
    onUpdateMessage:data => dispatch(updateMessage(dispatch,data)),
    onClickMessageBox:(data,sucB,errB) => dispatch(clickMessageBox(data,sucB,errB))
})
// Allow incoming props to override redux-provided props. Used to mock in tests.
const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
    {}, stateProps, dispatchProps, ownProps
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(LibraryComponent));

//export default injectIntl(LibraryComponent);
