import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {projectTitleInitialState} from '../reducers/project-title';
import downloadBlob from '../lib/download-blob';
import {defineMessages, injectIntl, intlShape} from 'react-intl';
import {
    updateWorkId
} from '../reducers/user';
import leap from '../lib/leap';
import {openLeapLoading,closeLeapLoading} from '../reducers/modals';
import {updateMessage} from '../reducers/message'
/**
 * Project saver component passes a downloadProject function to its child.
 * It expects this child to be a function with the signature
 *     function (downloadProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <SB3Downloader>{(downloadProject, props) => (
 *     <MyCoolComponent
 *         onClick={downloadProject}
 *         {...props}
 *     />
 * )}</SB3Downloader>
 */

const messages = defineMessages({
    saveSuccess: {
        id: 'gui.alerts.savesuccess',
        defaultMessage: 'The project file save success.',
        description: 'An error that displays when a local project file fails to load.'
    }
});
class SB3Downloader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'downloadProject'
        ]);
    }
    downloadProject (type) {
        this.props.saveProjectSb3().then(content => {
            if (this.props.onSaveFinished) {
                this.props.onSaveFinished();
            }
            if(this.props.isLeapLogin || !!type){
                //打开LOADING
                this.props.onOpenLeapLoading(); 
                let oCanvas = document.getElementsByTagName('canvas');
                //由于toDataURL 会出现黑色图像 所以用递归法 长度小于5062为黑；
                let currentTime = 0;
                let turnToSuccess = canvas=>{
                    let base64 = canvas.toDataURL("image/png");
                    let timer;
                    currentTime++;
                    if(base64.length<=5062 ){
                        console.log( base64.length )
                        timer = setTimeout(() => {
                            clearTimeout(timer);
                            timer = null;
                            if( currentTime < 40 ){
                                turnToSuccess( canvas )
                            }else{
                                this.props.onCloseLeapLoading();
                                saveImg( null )
                            }
                        }, 100);
                    }else{
                        console.log( base64,base64.length )
                        let parts = base64.split(';base64,');
                        let contentType = parts[0].split(':')[1];
                        let raw = window.atob(parts[1]);
                        let rawLength = raw.length;
                        let uInt8Array = new Uint8Array(rawLength);
                        for (let i = 0; i < rawLength; ++i) {
                            uInt8Array[i] = raw.charCodeAt(i);
                        }
                        let blob = new Blob([uInt8Array], {type: contentType});
                        saveImg( blob );
                    }
                }
                let saveImg = blob=>{
                    var form = new FormData();
                    form.append('dataFile', content);
                    if( blob ) form.append('iconFile', blob );
                    form.append("name",this.props.projectFilename.replace(".sb3",""));
                    if(this.props.workdId>0){
                        form.append("workId",this.props.workdId);
                    }
                    let url=leap.getServerURL()+"scratch3/saveScratch";
                    const options = {
                        "method":"POST",
                        "body":form,
                        credentials: 'include'
                    };
                    fetch(url,options)
                    .then(res=>res.json())
                    .catch(err=>{
                        this.props.onCloseLeapLoading();
                        this.props.onUpdateMessage({
                            type:'error',
                            message:err
                        }) 
                    })
                    .then(response=>{
                        console.log("res",response);
                        this.props.onCloseLeapLoading();
                        if(response.code==1000){
                            // if (type == 'HOME_WORK') {
                            //     // window.close()
                            // } else {
                                
                            // }
                            this.props.onSaveWorkSuccess(response.data.id); 
                            this.props.onUpdateMessage({
                                type:'success',
                                message:this.props.intl.formatMessage(messages.saveSuccess)
                            }) 
                        }else{
                            this.props.onUpdateMessage({
                                type:'error',
                                message:this.props.translation.saveFail
                            }) 
                        }
                    }); 
                };
                for( let i = 0 ; i < oCanvas.length ; i ++ ){
                    if( !!oCanvas[i].width ){ 
                        turnToSuccess( oCanvas[i] ) ;
                    }
                };
            } else {
                downloadBlob(this.props.projectFilename, content);
                return;
            }
            
        });
    }
    render () {
        const {
            children
        } = this.props;
        return children(
            this.props.className,
            this.downloadProject
        );
    }
}

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.sb3`;
};

SB3Downloader.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    onSaveFinished: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func,
    workdId: PropTypes.number,
    onSaveWorkSuccess: PropTypes.func,
    onOpenLeapLoading: PropTypes.func,
    onCloseLeapLoading: PropTypes.func,
    isLeapLogin:PropTypes.bool,
};
SB3Downloader.defaultProps = {
    className: ''
};
const mapDispatchToProps = dispatch => ({
    onSaveWorkSuccess: (id) => dispatch(updateWorkId(id)),
    onOpenLeapLoading: () => dispatch(openLeapLoading()),
    onCloseLeapLoading: () => dispatch(closeLeapLoading()),
    onUpdateMessage:data => dispatch(updateMessage(dispatch,data))
});

const mapStateToProps = state => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
    projectFilename: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState),
    workdId: state.scratchGui.user.currentProjectId,
    isLeapLogin:state.scratchGui.user.isLeapLogin,
    LeapLoadingVisible:state.scratchGui.modals.LeapLoading,
    translation: state.locales.translation

});

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps,
//     () => ({}) // omit dispatch prop
// )(SB3Downloader);

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SB3Downloader));
