import React from 'react';
import BAG from './images/bag.png'
import styles from './index.css'
import bindAll from 'lodash.bindall';
import {
  openLeapAir,
} from '../../reducers/modals';
import {connect} from 'react-redux';
class AirBag extends React.Component{
  constructor(){
    super();
    bindAll(this, [
      'handleOpen'
    ]);
  }
  handleOpen() {
    this.props.openAirModal()
  }
  render(){
    return(
      <div className={styles.leapAirBag}>
        <img id="leap_air_bag" src={BAG} alt="" onClick={()=>{this.handleOpen()}}/>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const user = state.scratchGui && state.scratchGui.user && state.scratchGui.user.data;
  return{
      user,
      language: state.locales.locale,
      vm: state.scratchGui.vm,
  }
}
const mapDispatchToProps = dispatch => ({
  openAirModal:() => dispatch(openLeapAir()), 
})
export default connect(mapStateToProps,mapDispatchToProps)(AirBag);