import React from 'react';
import styles from './index.css';
import classNames from 'classnames';
import {connect} from 'react-redux';
import Button from '../button/index.jsx'
import {clickMessageBox} from '../../reducers/message'
import bindAll from 'lodash.bindall';
class LeapMessageBox extends React.Component {
  constructor(){
    super()
    bindAll(this, [
      'handleConfirm',
      'handleCancel',
      'handleClose',
    ]);
  }
  handleConfirm(){
    this.props.message.sucBack()
    this.handleClose()
  }
  handleCancel(){
    this.props.message.errBack()
    this.handleClose()
  }
  handleClose(){
    this.props.onClickMessageBox({
      boxShow:false
    })
  }
  render() {
    const {message} = this.props
    return (
      <div className={styles.leapModal}>
        <div className={classNames(styles.leapMessageBox)}>
          <div className={styles.leapMessageHead}>
            {message.boxTitle}
            <div className={styles.closeMessage} onClick={this.handleClose}>x</div>
          </div> 
          <div className={styles.leapMessageBody}>
            {message.boxMessage}
          </div> 
          <div className={styles.leapMessageFoot}>
            <div className={styles.leapButton}>
              <Button click={this.handleCancel}>{this.props.translation.cancel}</Button>
              <Button type="primary" click={this.handleConfirm}>{this.props.translation.confirm}</Button>
            </div>
          </div> 
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return{
    message: state.scratchGui.message,
    translation: state.locales.translation
  }
}
const mapDispatchToProps = dispatch => ({
  onClickMessageBox:data=>dispatch(clickMessageBox(data))
})
export default connect(mapStateToProps,mapDispatchToProps)(LeapMessageBox);