import React from 'react';
import styles from './index.css';
import classNames from 'classnames';
const LeapButton =props => {
  return (
  <button className={classNames(styles.leapButton,{
    [styles.typeDefault]:!props.type,
    [styles.typePrimary]:props.type=='primary'
  })} onClick={props.click}>{props.children}</button>
  )
}

export default LeapButton