import React from 'react';
import styles from './share.css';
import closeImg from './close.png';
// import btnImg from './btn.png';
import {connect} from 'react-redux';
import {closeLeapShare} from '../../reducers/modals';
import {updateMessage} from '../../reducers/message'
const QRCode = require('qrcode.react');
class LeapShare extends React.Component{
  constructor(){
    super()
    this.state = {
      shareUrl:"https://scratch"+process.env.GO_IN+"/player.html?id="
    }
  }
  componentDidMount(){
    this.setState({
      shareUrl:this.state.shareUrl+this.props.currentProjectId
    })
  }
  copyUrl(){
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('value', this.state.shareUrl);
    input.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      this.props.onUpdateMessage({
        type:'success',
        message:this.props.translation.copySuc
      }) 
    }
    document.body.removeChild(input);
  }
  render(){
    return(
      <div className={styles.leapShareModal}>
        <div className={styles.leapShareBox}>
          <img className={styles.leapShareHead} src={closeImg} onClick={()=>{this.props.onCloseLeapShare()}}/> 
          <div className={styles.leapShareBody}>
            <p>
            {this.props.translation.shareMsg }
            </p>
            <div className={styles.leapQrCodeBox}>
              <div className={styles.leapQrCode}>
                <QRCode value={this.state.shareUrl}/>
              </div>
            </div>
            <div className={styles.leapShareOut}>
              <input type="text" value={this.state.shareUrl} readOnly/>
              <button type="button" onClick={()=>{this.copyUrl()}}>
                {this.props.translation.share }
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return{
    currentProjectId: state.scratchGui.user.currentProjectId,
    translation: state.locales.translation
  }
}
const mapDispatchToProps = dispatch => ({
  onCloseLeapShare:() => dispatch(closeLeapShare()), 
  onUpdateMessage:data => dispatch(updateMessage(dispatch,data))
})
export default connect(mapStateToProps,mapDispatchToProps)(LeapShare);