exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".public_leap-button_2yVoA{\r\n    border: none;\r\n    background: #fff;\r\n    border: 1px #409EFF solid;\r\n    border-radius: 5px;\r\n    background: #409EFF;\r\n    color: #fff;\r\n    padding: 8px 15px;\r\n    cursor: pointer;\r\n    margin-right:10px;\r\n}\r\n.public_leap-button_2yVoA[disabled]{\r\n    background:#ccc;\r\n    border: 1px #ccc solid;\r\n}", ""]);

// exports
exports.locals = {
	"leap-button": "public_leap-button_2yVoA",
	"leapButton": "public_leap-button_2yVoA"
};