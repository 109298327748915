export default class Animate {
  constructor(img, father) {
    this.cavs = null
    this.ctx = null
    this.img = img
    this.father = father
    this.timer = null
  }
  init(width, height) {
    this.cavs = document.createElement('canvas')
    this.ctx = this.cavs.getContext('2d')
    this.cavs.width = width
    this.cavs.height = height
    this.cavs.style.zIndex = '99999'
    this.cavs.style.position = 'absolute'
    this.cavs.style.left = 0
    this.cavs.style.top = 0
    this.father.appendChild(this.cavs)
  }
  zoomOut(I1, I2) {
    let I1x = I1.left + I1.width / 2
    let I1y = I1.top + I1.height / 2
    let I2x = I2.left
    let I2y = I2.top
    let x = I2x
    let y = I2y
    let width1 = I2.width
    let height1 = I2.height
    let x1 = I2x - I1x
    let y1 = I2y - I1y
    let xindex = -1
    let yindex = -1
    let width = I2.width
    let height = I2.height
    if (y1 < 0) yindex = 1
    if (x1 < 0) yindex = 1
    this.timer = setInterval(() => {
      if (Math.abs(x1) - Math.abs(y1) >= 0) {
        x += xindex * 10
        y += yindex * 10 * Math.abs(y1 / x1)
        width -= 10 * Math.abs(width1 / x1)
        height -= 10 * Math.abs(height1 / x1)
      } else {
        y += yindex * 10
        x += xindex * 10 * Math.abs(x1 / y1)
        width -= 10 * Math.abs(width1 / y1)
        height -= 10 * Math.abs(height1 / y1)
      }
      this.ctx.clearRect(0, 0, this.cavs.width, this.cavs.height)
      this.ctx.drawImage(this.img, x, y, width, height)
      if (x <= I1x) {
        clearInterval(this.timer)
        this.timer = null
        this.father.removeChild(this.cavs)
      }
    }, 5)
  }
  zoomIn(I1, I2, callback) {
    console.log(I1, I2)
    // let startW = 0
    // let startH = 0
    // let increace = 10
    // let startPl = I1.left + I1.width / 2
    // let startPt = I1.top - I1.height / 2
    callback()
  }
}
