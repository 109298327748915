const UPDATE_MATERIAL_ID = 'scratch-gui/material/UPDATE_MATERIAL_ID'
const UPDATE_TASK_LIST = 'scratch-gui/material/UPDATE_TASK_LIST'
const UPDATE_TASK_TITLE = 'scratch-gui/material/UPDATE_TASK_TITLE'
const UPDATE_MATERIAL_NAME = 'scratch-gui/material/UPDATE_MATERIAL_NAME'
const UPDATE_UNIT_LIST = 'scratch-gui/material/UPDATE_UNIT_LIST'
const UPDATE_TASK_ID = 'scratch-gui/material/UPDATE_TASK_ID'
const UPDATE_SECTION_ID = 'scratch-gui/material/UPDATE_SECTION_ID'
const initialState = {
  materialId:'',
  sectionId:'',
  pptUrl:'',
  taskList:[],
  taskTitle:'',
  taskId:'',
  materialName:'',
  sectionName: '',
  unitList:[]
}

const reducer = function (state, action) {
  if (typeof state === 'undefined') state = initialState
  switch (action.type) {
  case UPDATE_MATERIAL_ID:
    return Object.assign({}, state, {
      materialId:action.materialId
    });
  case UPDATE_TASK_LIST:
    return Object.assign({}, state, {
      pptUrl:action.pptUrl,
      taskList:action.taskList,
      sectionName:action.sectionName
    });
  case UPDATE_TASK_TITLE:
    return Object.assign({}, state, {
      taskTitle:action.taskTitle
    });
  case UPDATE_MATERIAL_NAME:
    return Object.assign({}, state, {
      materialName:action.materialName
    });
  case UPDATE_UNIT_LIST:
    return Object.assign({}, state, {
      unitList:action.unitList
    });
  case UPDATE_TASK_ID:
    return Object.assign({}, state, {
      taskId:action.taskId
    });
  case UPDATE_SECTION_ID:
    return Object.assign({}, state, {
      sectionId:action.sectionId
    });
  default:
    return state;
  }
}

const setMaterialId = function (materialId) {
  return {
      type: UPDATE_MATERIAL_ID,
      materialId: materialId
  }
}

const setTaskList = function(data){
  return {
    type: UPDATE_TASK_LIST,
    pptUrl: data.pptUrl,
    taskList: data.taskList,
    sectionName: data.sectionName
  }
}

const setSectionId = function(id){
  return {
    type: UPDATE_SECTION_ID,
    sectionId:id
  }
}

const setTaskTitle = function(taskTitle){
  return {
    type: UPDATE_TASK_TITLE,
    taskTitle: taskTitle
  }
}

const setMaterialName = function(name){
  return {
    type: UPDATE_MATERIAL_NAME,
    materialName: name
  }
}

const setUnitList = function(list){
  return {
    type: UPDATE_UNIT_LIST,
    unitList: list
  }
}
const setTaskId = function(id){
  return {
    type: UPDATE_TASK_ID,
    taskId: id
  }
}

export {
  reducer as default,
  initialState as materialInitialState,
  setMaterialId,
  setTaskList,
  setTaskTitle,
  setMaterialName,
  setUnitList,
  setTaskId,
  setSectionId
}
