module.exports = {
    'zh-cn':{
        material:"教材",
        materialName:"教材名称",
        all:"全部",
        unit:"单元",
        section:"小节",
        task:"任务",
        confirm:"确认",
        alert:"不支持的格式",
        saveLocal:"保存到本地",
        platForm:"立乐编程平台",
        whiteboard:'白板',
        save:'保存',
        share:'分享',
        shareMsg:'扫描二维码或复制链接进行分享项目',
        saveBeforeShare:'请先保存作品，再进行分享',
        copySuc:'复制成功',
        openMFaile:'打开教材失败',
        currentMaterial:'当前教材',
        selectUnit:'选择单元',
        selectSection:'选择小节',
        pleaseChoose:'请选择',
        openMaterial:'打开教材',
        firstMaterial: '这已经是第一个教材了',
        lastMaterial: '这已经是最后一个教材了',
        noMaterial:'没有数据',
        faile:'失败',
        saveFail:'保存失败',
        reset: '重置',
        cancel:'取消',
        replaceWord:'是否替换当前项目的内容？',
        notes:'提示',
        openPpt:"打开PPT",
        openCode:"打开代码",
        isDelete: '是否删除该作品？',
        faileCode59: '任务作品不能删除',
        faileCode60: '创建作业作品失败',
        scratchWork: 'scratch 作品'
    },
    en:{
        material:"Teaching material",
        materialName:"Material",
        all:"all",
        unit:"Unit",
        section:"Section",
        task:"Task",
        confirm:"Confirm",
        alert:"Unsupported media type",
        saveLocal:"Save it localy",
        platForm:"LL coding platform",
        whiteboard:'whiteboard',
        save:'Save',
        share:'Share',
        shareMsg:'Scan the QR code or copy the link to share the project',
        saveBeforeShare:'Please save your work before sharing',
        copySuc:'Copy success',
        openMFaile:'Open the textbook faile',
        currentMaterial:'Textbook',
        selectUnit:'Unit',
        selectSection:'Section',
        pleaseChoose:'Please select',
        openMaterial:'Open the textbook',
        firstMaterial: "It's the first one",
        lastMaterial: "It's the last one",
        noMaterial:'No data',
        faile:'fail',
        saveFail:'Save fail',
        reset: 'Reset',
        cancel: 'Cancel',
        replaceWord: 'Replace contents of the current project?',
        notes:'Notes',
        openPpt:"Open PPT",
        openCode:"Open Code",
        isDelete: 'Delete this work?',
        faileCode59: 'Task works cannot be deleted',
        faileCode60: 'Failed to create homework project',
        scratchWork: 'Scratch Project'
    }
}