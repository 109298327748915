exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index_leap-message-box_13oRy{\r\n  position: absolute;\r\n  top: 60px;\r\n  left: 50%;\r\n  -webkit-transform: translateX(-50%);\r\n      -ms-transform: translateX(-50%);\r\n          transform: translateX(-50%);\r\n  padding: 10px 50px;\r\n  z-index: 9999;\r\n  border-radius: 4px;\r\n  min-width: 200px;\r\n  text-align: center;\r\n  font-size: 16px;\r\n}\r\n.index_leap-message-default_38W59{\r\n  background: #edf2fc;\r\n  border: 1px solid #ebeef5;\r\n  color: #909399;\r\n}\r\n.index_leap-message-success_ciRLr{\r\n  background: #e1f3d8;\r\n  border: 1px solid #e1f3d8;\r\n  color: #67C23A;\r\n}\r\n.index_leap-message-warning_2QFq_{\r\n  background: #faecd8;\r\n  border: 1px solid #faecd8;\r\n  color: #E6A23C;\r\n}\r\n.index_leap-message-error_2r27M{\r\n  background: #fde2e2;\r\n  border: 1px solid #fde2e2;\r\n  color: #F56C6C;\r\n}", ""]);

// exports
exports.locals = {
	"leap-message-box": "index_leap-message-box_13oRy",
	"leapMessageBox": "index_leap-message-box_13oRy",
	"leap-message-default": "index_leap-message-default_38W59",
	"leapMessageDefault": "index_leap-message-default_38W59",
	"leap-message-success": "index_leap-message-success_ciRLr",
	"leapMessageSuccess": "index_leap-message-success_ciRLr",
	"leap-message-warning": "index_leap-message-warning_2QFq_",
	"leapMessageWarning": "index_leap-message-warning_2QFq_",
	"leap-message-error": "index_leap-message-error_2r27M",
	"leapMessageError": "index_leap-message-error_2r27M"
};