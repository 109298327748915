var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".share_leap_share_modal_JTOrm{\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background: rgba(0, 0, 0, 0.6);\r\n  z-index: 999;\r\n}\r\n.share_leap_share_box_eJkCv{\r\n  position: absolute;\r\n  left: 50%;\r\n  top: 50%;\r\n  -webkit-transform: translate(-50%,-50%);\r\n      -ms-transform: translate(-50%,-50%);\r\n          transform: translate(-50%,-50%);\r\n  width: 600px;\r\n  height: 350px;\r\n  background: #fff;\r\n  overflow: hidden;\r\n  border-radius: 14px;\r\n}\r\n.share_leap_share_head_3dXOf{\r\n  position: absolute;\r\n  top: 0;\r\n  right: 0;\r\n  width: 40px;\r\n  height: 40px;\r\n  cursor: pointer;\r\n}\r\n.share_leap_share_body_2gf5v{\r\n  width: 100%;\r\n  height: 100%;\r\n  border: 10px solid rgb(77, 151, 255);\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  padding: 30px;\r\n}\r\n.share_leap_share_body_2gf5v p {\r\n  text-align: center;\r\n  color: rgb(77, 151, 255);\r\n  margin-bottom: 25px;\r\n}\r\n.share_leap_qr_code_box_eY4ke{\r\n  text-align: center;\r\n  margin-bottom: 25px;\r\n}\r\n.share_leap_qr_code_3jcEL{\r\n  display: inline-block;\r\n  border: 2px #eee solid;\r\n  border-radius: 8px;\r\n  padding: 5px;\r\n}\r\n.share_leap_share_out_3l6lX{\r\n  padding: 0 60px;\r\n}\r\n.share_leap_share_out_3l6lX input{\r\n  border: none;\r\n  outline: none;\r\n  -webkit-appearance: none;\r\n  height: 34px;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  padding: 5px;\r\n  border: 1px #eee solid;\r\n  border-radius: 5px;\r\n  width: 320px;\r\n  margin-right: 10px;\r\n}\r\n.share_leap_share_out_3l6lX button{\r\n  border: none;\r\n  outline: none;\r\n  background:url(" + escape(require("./btn.png")) + ");\r\n  background-size: contain;\r\n  width: 70px;\r\n  height: 34px;\r\n  cursor: pointer;\r\n}", ""]);

// exports
exports.locals = {
	"leap_share_modal": "share_leap_share_modal_JTOrm",
	"leapShareModal": "share_leap_share_modal_JTOrm",
	"leap_share_box": "share_leap_share_box_eJkCv",
	"leapShareBox": "share_leap_share_box_eJkCv",
	"leap_share_head": "share_leap_share_head_3dXOf",
	"leapShareHead": "share_leap_share_head_3dXOf",
	"leap_share_body": "share_leap_share_body_2gf5v",
	"leapShareBody": "share_leap_share_body_2gf5v",
	"leap_qr_code_box": "share_leap_qr_code_box_eY4ke",
	"leapQrCodeBox": "share_leap_qr_code_box_eY4ke",
	"leap_qr_code": "share_leap_qr_code_3jcEL",
	"leapQrCode": "share_leap_qr_code_3jcEL",
	"leap_share_out": "share_leap_share_out_3l6lX",
	"leapShareOut": "share_leap_share_out_3l6lX"
};