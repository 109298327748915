import React from 'react';
import styles from './controls.css'
import bindAll from 'lodash.bindall';
import {
  closeLeapBoard,
} from '../../reducers/modals';
import {connect} from 'react-redux'
import classNames from 'classnames'
import {offset} from '../utils'
import Cavs from './canvas'

import mouseImg from './images/mouse.png'
import pencilImg from './images/pencil.png'
import textImg from './images/text.png'
import eraserImg from './images/eraser.png'
import deleteImg from './images/delete.png'
// import closeImg from './images/redclose.png'
import line1 from './images/line1.png'
import line2 from './images/line2.png'
import line3 from './images/line3.png'

class Controls extends React.Component{
  constructor(){
    super();
    bindAll(this, [
      'handleMove',
      'handleClose',
      'handleEnter',
      'handleOut',
    ]);
    this.state = {
      top: 48,
      left:300,
      controlList: [
        {name: 'mouse', src: mouseImg, active: true},
        {name: 'pencil', src: pencilImg},
        {name: 'text', src: textImg},
        {name: 'eraser', src: eraserImg},
        {name: 'delete', src: deleteImg}
      ],
      lineShow: false,
      lineList: [
        {color: '#FE2707', src: line1, active: true},
        {color: '#FFF004', src: line2},
        {color: '#00C0FF', src: line3}
      ],
      pointShow: false,
      pointList: [
        {color: '#FE2707', active: true},
        {color: '#FFF004'},
        {color: '#00C0FF'}
      ],
    }
  }
  handleMove(_e) {
    _e.stopPropagation()
    let xy = offset(document.querySelector('#leap_controls'))
    let _top = _e.clientY - xy.top
    let _left = _e.clientX - xy.left
    window.onmousemove = e => {
      this.setState({
        top: e.clientY - _top,
        left: e.clientX - _left
      })
    }
    window.onmouseup = e => {
      window.onmousemove = null;
    };
  }
  handleClose() {
    this.props.closeModal()
  }
  handleEnter(_item) {
    if (_item.name === 'pencil') {
      this.setState({
        lineShow: true,
        pointShow: false
      })
    } else if (_item.name === 'text') {
      this.setState({
        lineShow: false,
        pointShow: true
      })
    }
  }
  handleOut() {
    console.log('leave')
    this.setState({
      lineShow: false,
      pointShow: false
    })
  }
  handleSelect(_item) {
    let arr = this.resetArr(this.state.controlList,'name',_item.name)
    this.setState({
      controlList: arr
    });
    let cavs = document.querySelector('#leap_canvas');
    cavs.style.pointerEvents = '';
    switch (_item.name) {
    case 'mouse':
        cavs.style.pointerEvents = 'none';
      break;
    case 'pencil':
      Cavs.getInstance().allDie()
      Cavs.getInstance().drawLine()
      break;
    case 'text':
      Cavs.getInstance().allDie()
      Cavs.getInstance().drawText('.index_app_2mqDO', {top: 10})
      break;
    case 'eraser':
      Cavs.getInstance().allDie()
      Cavs.getInstance().drawEraser()
      break;
    case 'delete':
      Cavs.getInstance().drawClear()
      break;
    }
  }
  handleSelectLine(_item) {
    let arr = this.resetArr(this.state.lineList,'color',_item.color)
    this.setState({
      lineList: arr,
      lineShow: false
    })
    Cavs.getInstance().allDie()
    Cavs.getInstance().drawLine()
    Cavs.getInstance().changeOptions({
      strokeStyle: _item.color
    })
  }
  handleSelectPoint(_item) {
    let arr = this.resetArr(this.state.pointList,'color',_item.color)
    this.setState({
      pointList: arr,
      pointShow: false
    })
    Cavs.getInstance().allDie()
    Cavs.getInstance().drawText('.index_app_2mqDO', {top: 10})
    Cavs.getInstance().changeOptions({
      fillStyle: _item.color
    })
  }
  resetArr(arr, code, name) {
    arr.forEach(_item=>{
      _item.active = false;
      if (_item[code] === name) _item.active = true
    })
    return arr
  }
  componentDidMount() {
    Cavs.getInstance().init('leap_canvas', window.innerWidth, window.innerHeight)
    // Cavs.getInstance().drawLine()
    // this.handleSelect({name: 'mouse', src: mouseImg})
    let cavs = document.querySelector('#leap_canvas');
    cavs.style.pointerEvents = 'none';
    document.onkeydown = e => {
      if (e.keyCode === 18) {
        if (cavs.style.pointerEvents == 'none') {
          this.handleSelect({name: 'pencil', src: pencilImg})
        } else {
          this.handleSelect({name: 'mouse', src: mouseImg})
        }
      }
    }
  }
  componentWillUnmount(){
    document.onkeydown = null
  }
  render(){
    return(
      <div className={styles.leapControls} id="leap_controls" style={{
        'left': this.state.left + 'px',
        'top': this.state.top + 'px'
      }} onMouseLeave={()=>{this.handleOut()}}>
        <div className={styles.leapControlsTool}>
          <div className={styles.leapControlsDrag} onMouseDown={this.handleMove}></div>
          {
            this.state.controlList.map((item,index) => {
              return (
                <div className={classNames(styles.leapControlsItem,{
                  [styles.leapControlsItemActive]:item.active
                })} key={index} 
                onMouseEnter={()=>{this.handleEnter(item)}}
                onClick={()=>{this.handleSelect(item)}}
                >
                  {/* <img src={item.src} alt=""/> */}
                  <div className={classNames({
                    [styles.leapItemMouse] : item.name == 'mouse',
                    [styles.leapItemPencil] : item.name == 'pencil',
                    [styles.leapItemText] : item.name == 'text',
                    [styles.leapItemEraser] : item.name == 'eraser',
                    [styles.leapItemDelete] : item.name == 'delete'
                  })}></div>
                </div>
              )
            })
          }
          <div className={styles.leapControlsDrag} onMouseDown={this.handleMove}></div>
        </div>
        {
          this.state.lineShow ?
          <div className={classNames(styles.leapExtend,styles.leapExtendLine)}>
            {
              this.state.lineList.map((item,index) => {
                return (
                  <div className={classNames(styles.leapExtendItem,{
                    [styles.leapExtendItemActive]:item.active
                  })} key={index} onClick={()=>{this.handleSelectLine(item)}}>
                    <img src={item.src} alt=""/>
                  </div>
                )
              })
            }
          </div> : null
        }
        {
          this.state.pointShow ? 
          <div className={classNames(styles.leapExtend,styles.leapExtendPoint)}>
            {
              this.state.pointList.map((item,index) => {
                return (
                  <div className={classNames(styles.leapExtendItem,{
                    [styles.leapExtendItemActive]:item.active
                  })} key={index} onClick={()=>{this.handleSelectPoint(item)}}>
                    <span style={{'background': item.color}}></span>
                  </div>
                )
              })
            }
          </div> : null
        }
        <div className={styles.leapControlsClose} onClick={this.handleClose}>
          {/* <img src="./images/redclose.png" alt=""/> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{}
}
const mapDispatchToProps = dispatch => ({
  closeModal:() => dispatch(closeLeapBoard()), 
})
export default connect(mapStateToProps,mapDispatchToProps)(Controls);