import React from 'react';
import styles from './index.css'
import closeImg from './images/close2.png'
import bindAll from 'lodash.bindall';
import {
  closeLeapAirMaterial,
  openLeapLoading,
  closeLeapLoading
} from '../../reducers/modals';
import leap from '../../lib/leap';
import {connect} from 'react-redux';
import {
  setTaskList,
  setTaskTitle,
  setTaskId,
  setSectionId
} from '../../reducers/material'
import {
  updateWorkId,
  updateLoadingStatus,
} from '../../reducers/user';
import {dealPptUrl} from '../utils'
import {
  requestNewProject
} from '../../reducers/project-state';
import analytics from '../../lib/analytics';
class AirMaterial extends React.Component{
  constructor(){
    super()
    bindAll(this,[
      'closeModal',
      'changeUnit',
      'changeSection',
      'openMaterial',
      'getDefaultWork',
      'selectHomework'
    ])
    this.state = {
      unitId:'',
      sectionId:'',
      sectionList:[]
    }
  }
  getDefaultWork(body) { // 新版本获取默认代码
    this.props.onOpenLeapLoading()
    this.props.onNewPage(false)
    let url = leap.getServerURL() + 'workapi/createWorkAfterLogin?' + body
    fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'}
    }).then(res=>res.json()).then(res=>{
        if (res.status === '0' && res.data && res.data.id) {
            this.props.onUpdateWorkId(res.data.id)
            this.props.onGetUserInfo({data:res.user});
            this.selectHomework( res.data )
        } else {
          this.props.onCloseLeapLoading()
        }
    }).catch(()=>{
        this.props.onGetUserInfo({data:{}});
        this.props.onCloseLeapLoading()
    })
  }
  selectHomework(work){
    console.log("通过taskId获取新作品",work)
    let _this = this;
    if( !work.codeUrl || !work.id ) return;
    let url = "https://cdn"+process.env.RESOURCE+"/" + work.codeUrl + "?v=" + new Date().getTime();
    this.props.setProjectTitle(work.workName);
    this.props.onSelectWork( work.id);
    fetch(url ,{
        method:"GET",
        dataType:"blob"
    })
    .then(res=>res.blob())
    .then(blob=>{
      console.log('哪到作品了')
        var reader = new FileReader();
        reader.onload =(e)=> {
            let data =e.target.result;
            _this.props.vm.loadProject(data).then(res => {
                analytics.event({
                    category: 'project',
                    action: 'Import Project File',
                    nonInteraction: true
                });
                _this.props.onUpdateProjectTitle( work.workName );
                _this.props.onCloseLeapLoading()
            }).catch(error => {
              _this.props.onCloseLeapLoading()
            });
        };
        reader.readAsArrayBuffer(blob);
    })
    .catch(err=>{
        console.log("fetch error"+err);
    });
  }
  closeModal() {
    this.props.closeMModal()
  }
  changeUnit(e){
    if (e.target.value === '') return ;
    this.setState({
      unitId:e.target.value,
      sectionList:[]
    },()=>{
      let url = leap.getServerURL() + 'mc/getSectionCombobox?' + 'unitId=' + this.state.unitId
      fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'}
      }).then(res=>res.json()).then(res=>{
          if (res.status === '0' && res.data && res.data.length) {
            this.setState({
              sectionList:res.data
            })
          }
      })
    })
  }
  changeSection(e){
    this.setState({
      sectionId:e.target.value
    })
  }
  openMaterial(){
    let _item
    this.state.sectionList.forEach(item=>{
      if(item.id == this.state.sectionId){
        _item = item
      }
    })
    let url = leap.getServerURL() + 'mc/queryTaskCombobox?sectionId=' + _item.id
    fetch(url,{
        method: 'POST',
        credentials: 'include',
        headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'}
    }).then(res=>res.json()).then(res=>{
        if(res.status === '0'){
          let section = res.section
          this.props.onSetTaskList({
              pptUrl:dealPptUrl(section.fileUrl)||'',
              taskList:res.data||[],
              sectionName:section.sectionName
          })
          this.props.onSetSectionId(_item.id)
          if (res.data&&res.data.length) {
            this.props.onSetTaskTitle(res.data[0].task_title)
            this.props.onSetTaskId(res.data[0].id)
            this.getDefaultWork(`type=3&sectionId=${section.sectionId}&taskId=${res.data[0].id}`)
          }
          this.props.closeMModal()
        } else {
          alert(this.props.translation.openMFaile)
        }
    })
  }
  render(){
    return(
      <div className={styles.leapAirMaterial}>
        <div className={styles.leapMaterialBody}>
          <img className={styles.leapMaterialClose} src={closeImg} alt="" onClick={this.closeModal}/>
          <div className={styles.leapMaterialSelect}>
            <h1>{this.props.translation.material}</h1>
            <div className={styles.leapMaterialImg}>
              <img src="" alt=""/>
            </div>
            <div className={styles.leapMaterialFormItem}>
              <label className={styles.leapMaterialFormLabel}>{this.props.translation.currentMaterial}：</label>
              <div className={styles.leapMaterialFormContent}>{this.props.material.materialName}</div>
            </div>
            <div className={styles.leapMaterialFormItem}>
              <label className={styles.leapMaterialFormLabel}>{this.props.translation.selectUnit}：</label>
              <div className={styles.leapMaterialFormContent}>
                <select placeholder={this.props.translation.pleaseChoose} value={this.state.unitId} onChange={e=>{ this.changeUnit(e) }}>
                  <option value=''>{this.props.translation.pleaseChoose}</option>
                  {
                    this.props.material.unitList.map((item,index)=>{
                      return (
                        <option value={item.id} key={index}>{item.unitName}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
            <div className={styles.leapMaterialFormItem}>
                <label className={styles.leapMaterialFormLabel}>{this.props.translation.selectSection}：</label>
              <div className={styles.leapMaterialFormContent}>
                <select placeholder={this.props.translation.pleaseChoose} value={this.state.sectionId} onChange={e=>{ this.changeSection(e) }}>
                  <option value="">{this.props.translation.pleaseChoose}</option>
                  {
                    this.state.sectionList.map((item,index)=>{
                      return (
                        <option value={item.id} key={index}>{item.sectionName}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
            <div className={styles.leapMaterialButton} onClick={this.openMaterial}>
              {this.props.translation.openMaterial}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return{
    material:state.scratchGui.material,
    translation: state.locales.translation
  }
}
const mapDispatchToProps = dispatch => ({
  closeMModal:() => dispatch(closeLeapAirMaterial()),
  onSetTaskList:data => dispatch(setTaskList(data)),
  onSetTaskTitle:data => dispatch(setTaskTitle(data)),
  onSetTaskId:id => dispatch(setTaskId(id)),
  onSetSectionId:id => dispatch(setSectionId(id)),
  onUpdateWorkId: id => dispatch(updateWorkId(id)),
  onGetUserInfo:(data)=>dispatch(updateLoadingStatus(data)),
  onNewPage:needSave =>{
    dispatch(requestNewProject(needSave))
    dispatch(updateWorkId(0));
  },
  onOpenLeapLoading: () => dispatch(openLeapLoading()),
  onCloseLeapLoading: () => dispatch(closeLeapLoading()),
})
export default connect(mapStateToProps,mapDispatchToProps)(AirMaterial);
