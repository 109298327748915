import React from 'react';
import classNames from 'classnames';
import publics from '../../leaplearner/public/public.css'
const QRCode = require('qrcode.react');

import styles from './qrcodeModal.css';

class QrModal extends React.Component {
    constructor (props) {
        super(props);
    }
    copyUrl(){
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.setAttribute('value', this.props.shareUrl);
        input.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            alert('复制成功');
        }
        document.body.removeChild(input);
    }
    render(){
        return (
            <div className={styles.leapQrcodeModal} >
                
                <div className={styles.leapQrcodeContainer}>
                    <div className={styles.leapQrcodeClose}>
                        <a href="javascript:void(0)" className={styles.leapQrcodeCloseButton} onClick={()=>{this.props.clearShareUrl()}}>关闭</a>
                    </div>
                    <QRCode value={this.props.shareUrl}/>
                    <p id="leap_qrcopy" className={styles.leapQrcodeUrl}>{this.props.shareUrl}</p>
                    <button className={publics.leapButton} onClick={ e=>{ e.stopPropagation();this.copyUrl(); } }>复制</button>
                </div>

            </div>
        )
    }
}
export default QrModal;