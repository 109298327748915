/**
 * @fileoverview
 * Utility function to detect locale from the browser setting or paramenter on the URL.
 */
import Cookie from 'js-cookie'
import queryString from 'query-string';/**
 * look for language setting in the browser. Check against supported locales.
 * If there's a parameter in the URL, override the browser setting
 * @param {Array.string} supportedLocales An array of supported locale codes.
 * @return {string} the preferred locale
 */
const GetCookieDomain = () => {
    var host = location.hostname;
    var ip = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    if (ip.test(host) === true || host === 'localhost') return host;
    var regex = /([^]*).*/;
    var match = host.match(regex);
    if (typeof match !== "undefined" && null !== match) host = match[1];
    if (typeof host !== "undefined" && null !== host) {
     var strAry = host.split(".");
     if (strAry.length > 1) {
      host = strAry[strAry.length - 2] + "." + strAry[strAry.length - 1];
     }
    }
    return '.' + host;
}

const getCookie = key => {
    let arr = document.cookie.split(';')
    let params = {}
    try{
        arr.forEach(item => {
            let brr = item.split("=")
            params[brr[0]] = brr[1]
        })
    }catch(err){}
    return params[key] || ''
}

const detectLocale = supportedLocales => {
    let locale = 'en'; // default
    let browserLocale = window.navigator.userLanguage || window.navigator.language;
    browserLocale = browserLocale.toLowerCase();
    // try to set locale from browserLocale
    const FROM_LOCALE = Cookie.get('new_Language')
    if (supportedLocales.includes(browserLocale)) {
        locale = FROM_LOCALE || browserLocale;
    } else {
        browserLocale = browserLocale.split('-')[0];
        if (supportedLocales.includes(browserLocale)) {
            locale = FROM_LOCALE || browserLocale;
        }
    }
    // 设置系统语言
    let href = window.location.href.split('?')
    if(href.length>1){
        let arr = href[1].split('&')
        if(arr.includes('lang=1')) locale = 'zh-cn'
        if(arr.includes('lang=2')) locale = 'en'
        console.log('href-locale',locale)
    }
    console.log('get_new_Language',Cookie.get('new_Language'),'<--->',FROM_LOCALE)
    try{
        locale = locale.toLocaleLowerCase()
        console.log('locale',locale)
    }catch(err){
        locale = 'zh-cn'
    }
    const queryParams = queryString.parse(location.search);
    // Flatten potential arrays and remove falsy values
    const potentialLocales = [].concat(queryParams.locale, queryParams.lang).filter(l => l);
    if (!potentialLocales.length) {
        return locale;
    }

    const urlLocale = potentialLocales[0].toLowerCase();
    if (supportedLocales.includes(urlLocale)) {
        return urlLocale;
    }

    return locale;
};

export {
    detectLocale
};
