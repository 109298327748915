import React from 'react';
import styles from './leapModal.css';


const ModalComponent = props => (
    <div className={styles.modalContainer}>
        <div className={styles.modalInside} style={ props.modalStyle }>
            <div className={styles.modalHeader}>
                <p>{props.title?props.title:'Notes'}</p>
                <span onClick={props.handleClose}>x</span>
            </div>
            <div className={styles.modalBody}>{props.children}</div>
        </div>
    </div>
)

export default ModalComponent;