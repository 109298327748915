exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".timeTable_forbiden_1nZ5U{\r\n    overflow: hidden;\r\n}\r\n.timeTable_time-form_16ZN8{\r\n    padding: 20px 0;\r\n    overflow: hidden;\r\n}\r\n.timeTable_time-section_AUiEj{\r\n    float: left;\r\n    padding: 5px 10px 5px 0;\r\n}\r\n.timeTable_time-section_AUiEj label{\r\n    padding-right: 15px;\r\n}\r\n.timeTable_time-section_AUiEj select{\r\n    border: none;\r\n    outline: none;\r\n    width: 120px;\r\n    border: 1px solid #666;\r\n    border-radius: 5px;\r\n    height: 30px;\r\n    padding-left: 5px;\r\n    cursor: pointer;\r\n}\r\n.timeTable_ppt-container_2L2St{\r\n    width: 100%;\r\n    height: 100%;\r\n}", ""]);

// exports
exports.locals = {
	"forbiden": "timeTable_forbiden_1nZ5U",
	"time-form": "timeTable_time-form_16ZN8",
	"timeForm": "timeTable_time-form_16ZN8",
	"time-section": "timeTable_time-section_AUiEj",
	"timeSection": "timeTable_time-section_AUiEj",
	"ppt-container": "timeTable_ppt-container_2L2St",
	"pptContainer": "timeTable_ppt-container_2L2St"
};