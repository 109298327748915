import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import VM from 'scratch-vm';
import {STAGE_SIZE_MODES} from '../lib/layout-constants';
import {setStageSize} from '../reducers/stage-size';
import {setFullScreen} from '../reducers/mode';

import {connect} from 'react-redux';

import StageHeaderComponent from '../components/stage-header/stage-header.jsx';
import leap from '../lib/leap'
import analytics from '../lib/analytics';
import {objectToUrl} from '../leaplearner/utils'
import {openLeapLoading,closeLeapLoading} from '../reducers/modals';
class StageHeader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleKeyPress',
            'handleClick'
        ]);
    }
    handleClick() {
        let _this = this
        let params = {
            type: 3,
            workId: this.props.workId,
            taskId: this.props.taskId
        }
        if (!params.workId || !params.taskId) {
            return
        }
        let url = leap.getServerURL() + '/mc/resetUserWork?' + objectToUrl(params)
        //
        fetch(url,{
            method: 'POST',
            credentials: 'include',
            headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'}
        }).then(res=>res.json()).then(res=>{
            if (res.status === '0' && res.data && res.data.codeUrl) {
                let codeurl = "https://cdn"+process.env.GO_IN+"/" + res.data.codeUrl + "?v=" + new Date().getTime();
                this.props.onOpenLeapLoading()
                fetch(codeurl ,{
                    method:"GET",
                    dataType:"blob"
                })
                .then(res=>res.blob())
                .then(blob=>{
                    var reader = new FileReader();
                    reader.onload =(e)=> {
                        let data =e.target.result;
                        console.log('重置代码',data)
                        _this.props.vm.loadProject(data)
                        .then(res => {
                            analytics.event({
                                category: 'project',
                                action: 'Import Project File',
                                nonInteraction: true
                            });
                            _this.props.onCloseLeapLoading()
                        })
                        .catch(error => {
                            _this.props.onCloseLeapLoading()
                        });
                    };
                    reader.readAsArrayBuffer(blob);
                })
            }
        })
    }
    componentDidMount () {
        document.addEventListener('keydown', this.handleKeyPress);
    }
    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    handleKeyPress (event) {
        if (event.key === 'Escape' && this.props.isFullScreen) {
            this.props.onSetStageUnFull(false);
        }
    }
    render () {
        const {
            ...props
        } = this.props;
        return (
            <StageHeaderComponent
                {...props}
                click={this.handleClick}
                onKeyPress={this.handleKeyPress}
            />
        );
    }
}

StageHeader.propTypes = {
    isFullScreen: PropTypes.bool,
    isPlayerOnly: PropTypes.bool,
    onSetStageUnFull: PropTypes.func.isRequired,
    showBranding: PropTypes.bool,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => {
    const user = state.scratchGui && state.scratchGui.user && state.scratchGui.user.data;
    return {
        stageSizeMode: state.scratchGui.stageSize.stageSize,
        showBranding: state.scratchGui.mode.showBranding,
        isFullScreen: state.scratchGui.mode.isFullScreen,
        isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
        taskId: state.scratchGui.material.taskId,
        workId: state.scratchGui.user.currentProjectId
    }
};

const mapDispatchToProps = dispatch => ({
    onSetStageLarge: () => dispatch(setStageSize(STAGE_SIZE_MODES.large)),
    onSetStageSmall: () => dispatch(setStageSize(STAGE_SIZE_MODES.small)),
    onSetStageFull: () => dispatch(setFullScreen(true)),
    onSetStageUnFull: () => dispatch(setFullScreen(false)),
    onOpenLeapLoading: () => dispatch(openLeapLoading()),
    onCloseLeapLoading: () => dispatch(closeLeapLoading()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StageHeader);
